import { Standard } from 'models/Standard';
import { ScoreWords } from 'models/enums/Standards';
import { convertSkillIntialToWord } from 'utils/helpers/stringHelpers';
import { IMenuListItems } from 'models/Menus';


export interface ILineGraphData{
    proficiencyScore:number;
    proficiencyArray: number[];
    graphLevelsArray: string[];
}

// Retrieve data for skills/standards line graph
export function getLineGraphData(skill: Standard): ILineGraphData{

    const proficiencies: Array<number> = [ 
                            skill.proficiency.exceedsCount, 
                            skill.proficiency.meetsCount,
                            skill.proficiency.approachingCount, 
                            skill.proficiency.developingCount
                        ];

    const graphLevels: Array<string> = skill.assignments.filter(assignment => {
        return (assignment.score !== null) && (assignment.standardEventActive === 1);
    }).sort(function compare(assignmentA:any, assignmentB:any) {
        const dateA = +new Date(assignmentA['dueDate']);
        const dateB = +new Date(assignmentB['dueDate']);
        return dateA - dateB;
    }).map(item => { 
        return item.score 
    });

    const proficiencyScore: number = skill.proficiency.proficiencyScore ? skill.proficiency.proficiencyScore : 0;

    return {
        proficiencyScore: proficiencyScore,
        proficiencyArray: proficiencies,
        graphLevelsArray: graphLevels
    }
}

export function isSkillType(skillIntial: string, skillWord: string): boolean{
    const convertedSkillWord:string = convertSkillIntialToWord(skillIntial);
    if(convertedSkillWord && convertedSkillWord.toLocaleLowerCase() === skillWord.toLocaleLowerCase())
        return true;
    return false;
}

export function filterSkillIncompleteOptions(skillOptions: IMenuListItems[], hasMissingAssignments: boolean): IMenuListItems[]{
    if(!hasMissingAssignments)
        return skillOptions.filter(option => option.primaryText !== ScoreWords.Incomplete)
    return skillOptions;
}
 