export interface WeeklyGrowth {
    studentPersonId: number;
    sectionId: number;
    task: string;
    taskID:number;
    sequence?: number;
    isFinalComment?: boolean;
    score: string;
    comments: string;
    hideProjectedGrade?:boolean;
}
 
export enum WeekType{
    FinalWeek = `FinalWeek`,
    CurrentWeek = `CurrentWeek`,
    PastWeek = `PastWeek`,
    NotFinalWeek = `NotFinalWeek`
}
// finals (taskIds) week
export enum FinalsWeekId{
    S1_Final_Grade = 17,
    S2_Final_Grade = 18
}