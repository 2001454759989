import { ScoreInitials, ScoreWords, ScoreNumbers } from 'models/enums/Standards';
import { GradebookEntry } from 'models/GradebookEntry';

export function convertScoreToWord(score:string) : string {
    let scoreWord: string = '';
    switch(score ? score.toUpperCase() : score) {
        case ScoreNumbers.Four:            
            scoreWord = ScoreWords.Exceeds;
            break;        
        case ScoreNumbers.Three:                 
            scoreWord = ScoreWords.Meets;
            break;
        case ScoreNumbers.Two:            
            scoreWord = ScoreWords.Approaching;
            break;        
        case ScoreNumbers.One:                 
            scoreWord = ScoreWords.Developing;
            break;
        case ScoreNumbers.Zero:                 
            scoreWord = ScoreWords.Null;
            break;
        case ScoreInitials.T:                    
            scoreWord = ScoreWords.TurnedIn;
            break;
        case ScoreInitials.TL:                   
            scoreWord = ScoreWords.TurnedInLate;
            break;
        case ScoreInitials.P:                    
            scoreWord = ScoreWords.Prepared;
            break;
        case ScoreInitials.PP:                    
            scoreWord = ScoreWords.PartiallyPrepared;
            break;
        case ScoreInitials.NP:                    
            scoreWord = ScoreWords.NotPrepared;
            break;
        case ScoreInitials.M:                   
            scoreWord = ScoreWords.Missing;
            break;
        case ScoreInitials.N:                    
            scoreWord = ScoreWords.NotCompleted;
            break;
        default:
            scoreWord = score;
        break;
    }
    return scoreWord;
}

export function convertSkillIntialToWord(skillIntial:string) : string {
    let scoreWord: string = '';
    switch(skillIntial ? skillIntial.toUpperCase() : skillIntial) {
        case ScoreInitials.E:            
            scoreWord = ScoreWords.Exceeds;
            break;        
        case ScoreInitials.M:                 
            scoreWord = ScoreWords.Meets;
            break;
        case ScoreInitials.A:            
            scoreWord = ScoreWords.Approaching;
            break;        
        case ScoreInitials.D:                 
            scoreWord = ScoreWords.Developing;
            break;
        case ScoreInitials.I:
        case ScoreNumbers.Five:
            scoreWord = ScoreWords.Incomplete;
            break; 
        default:
            scoreWord = skillIntial;
        break;
    }
    return scoreWord;
}


export function convertHomeworkScoreToWord(score:string) : string {
    let scoreWord: string = '';
    switch(score ? score.toUpperCase() : score) {
        case ScoreNumbers.Seven:            
            scoreWord = ScoreWords.TurnedIn;
            break;        
        case ScoreNumbers.Six:            
            scoreWord = ScoreWords.TurnedInLate;
            break;        
        case ScoreNumbers.Five:            
            scoreWord = ScoreWords.Missing;
            break;               
        case ScoreNumbers.Three:                 
            scoreWord = ScoreWords.Prepared;
            break;
        case ScoreNumbers.Two:            
            scoreWord = ScoreWords.PartiallyPrepared;
            break;        
        case ScoreNumbers.One:                 
            scoreWord = ScoreWords.NotPrepared;
            break;
        case ScoreNumbers.Zero:                 
            scoreWord = ScoreWords.NotCompleted;
            break;
            case ScoreInitials.T:                    
            scoreWord = ScoreWords.TurnedIn;
            break;
        case ScoreInitials.TL:                   
            scoreWord = ScoreWords.TurnedInLate;
            break;
        case ScoreInitials.P:                    
            scoreWord = ScoreWords.Prepared;
            break;
        case ScoreInitials.PP:                    
            scoreWord = ScoreWords.PartiallyPrepared;
            break;
        case ScoreInitials.NP:                    
            scoreWord = ScoreWords.NotPrepared;
            break;
        case ScoreInitials.M:                   
            scoreWord = ScoreWords.Missing;
            break;
        case ScoreInitials.N:                    
            scoreWord = ScoreWords.NotCompleted;
            break;
        default:
            scoreWord = score;
        break;
    }
    return scoreWord;
}

export function convertScoreToInitial(score:string) : string {
    let scoreInitial: string = '';
    switch(score) {
        case ScoreNumbers.Four:            
            scoreInitial = ScoreInitials.E;
            break;        
        case ScoreNumbers.Three:                 
            scoreInitial = ScoreInitials.M;
            break;
        case ScoreNumbers.Two:            
            scoreInitial = ScoreInitials.A;
            break;        
        case ScoreNumbers.One:                 
            scoreInitial = ScoreInitials.D;
            break;    
        default:
            scoreInitial = score;
        break;
    }
    return scoreInitial;
}

export function convertStandardActiveToWord(standardEventActive:number | undefined) : string {
    let activeWord: string = '';
    switch(standardEventActive) {
        case 0:            
            activeWord = 'No';
            break;        
        case 1:                 
            activeWord = 'Yes';
            break;
        case undefined:                 
            activeWord = '';
            break;      
        default:
            activeWord = standardEventActive !== null ? standardEventActive.toLocaleString() : '';
        break;
    }
    return activeWord;
}

export function areCompletedAssignments(standardScoreWord:string) : boolean {
    switch(standardScoreWord) {
        case ScoreWords.TurnedIn:
        case ScoreWords.TurnedInLate:
        case ScoreWords.Prepared:
        case ScoreWords.PartiallyPrepared:
            return true;               
        case ScoreWords.Missing:
        case ScoreWords.NotCompleted:
        case ScoreWords.NotPrepared:                  
            return false; 
        default:
            return false; 
    }
}


export function getBooleanString(boolean:boolean | undefined) : string{
    switch(boolean) {
        case true:
            return 'true';               
        case false:                  
            return 'false'; 
        default:
            return ''; 
    }
}

export function projectedGradeHasMissing(projectedGrade:string | undefined | null) : boolean {
    if(projectedGrade !== null && projectedGrade !== undefined && projectedGrade.toLowerCase().includes('i/')){
        return true;
    }
    return false;
}

export function getLastSubString(string: string | null | undefined): string {
	if (!string || !string.trim()) {
		return "";
	}
	const stringArray = string ? string.split(" ") : [];
	const result = stringArray.length
		? stringArray[stringArray.length - 1]
		: "";
	return result;
}

export function pluralize(label: string, entities: Array<any> | number | undefined | null): string {
    if (!entities) {
        // No entities found, treating as "0"
        return `${label}s`;
    } else if (typeof entities === 'number') {
        return `${label}${entities === 1 ? '' : 's'}`;
    }
    return `${label}${(entities as Array<any>).length === 1 ? '' : 's'}`;
}

export function getAOrAn(upcomingPhrase: string): string {
    return `a${['a','e','i','o','u'].some(c => upcomingPhrase.toLowerCase().startsWith(c)) ? 'n' : ''}`;
}

export function showFinalLabel(grades: GradebookEntry[]): string { 
    let label:string = '';
    const includesFinalGrades = grades.some(g => g.assessment !== null && g.assessment.isVerified === true && g.assessment.finalGrade);
    const onlyFinalGrades = grades.every(g => g.assessment !== null && g.assessment.isVerified === true && g.assessment.finalGrade); 
    const includesProjectGrades = grades.some(g => g.assessment !== null && g.assessment.isVerified === false && g.assessment.projectedGrade); 
    const onlyProjectGrades = grades.every(g => g.assessment !== null && g.assessment.isVerified === false && g.assessment.projectedGrade);
    if (includesFinalGrades && includesProjectGrades) {
        label = "Projected / Final Grade";    
    } else if(onlyProjectGrades){
        label = "Projected Grade";
    } else if(onlyFinalGrades){
        label = "Final Grade";
    } else if(includesProjectGrades){
        label = "Projected Grade";
    }else if(includesFinalGrades){
        label = "Final Grade";
    }else{
        label = "Projected Grade";
    }

    return label;
}