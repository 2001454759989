import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INewComment } from '../../models/MandatoryTargetedTutoring';
import { TutorView  } from '../../models/TutorView';
import {ISortBy} from '../../models/enums/Filter';
import { sortTutorViews } from '../../utils/helpers/mttHelpers';
import moment from 'moment'; 

export interface IMandatoryTargetedTutoringState {
    allMttStudents: TutorView[];
    loadingMttStudents: boolean;
    errorLoadingMttStudents: boolean;
    newInterventionCommentAdded: boolean;
    sortingMttStudents: boolean;
}

export const initialState:IMandatoryTargetedTutoringState = {
    allMttStudents: [],
    loadingMttStudents: false,
    errorLoadingMttStudents: false,
    newInterventionCommentAdded:false,
    sortingMttStudents: false,
};



const mandatoryTargetedTutoringSlice = createSlice({
  name: `mandatoryTargetedTutoringStudents`,
  initialState,
  reducers: {
    /** Reducer Action to when first intializing get/fetch of MTT Active Students List*/ 
    initializeMttStudentsAction: state => {
      state.loadingMttStudents = true;
    },
    /** Reducer Action to update MTT Active Students List */ 
    updateMttStudentListAction: (state, action: PayloadAction<TutorView[]>) => {
      state.allMttStudents = action.payload;
      state.loadingMttStudents = false;
      state.errorLoadingMttStudents = false;
      state.newInterventionCommentAdded = false;
    },
    /** Reducer Action to add a new comment to MTT Active Students List */ 
    addMttStudentInterventionCommentAction: (state, action: PayloadAction<INewComment>) => {
        const { studentId, newComment } = action.payload
        state.allMttStudents = state.allMttStudents.map((mttStudent) => {
            if(studentId && newComment && (mttStudent.studentPersonId === studentId) && (mttStudent.interventionId === newComment.interventionId)) {
                // Return a new mttStudent object
                return {
                    ...mttStudent,
                    latestComment: newComment.comments,
                    commenterName: newComment.commentAuthorName,
                    commentDateTime: moment(newComment.commentDate)
                }
          }
          // Leave every other mttStudent item unchanged
          return mttStudent;
        })
        state.newInterventionCommentAdded = true;
        state.loadingMttStudents = false;
        state.errorLoadingMttStudents = false; 
    },
    /** Reducer Action sort mtt student rows */
    sortMttStudentListAction: (state, action: PayloadAction<ISortBy>) => {
        const { sortBy, order } = action.payload;
        state.allMttStudents = sortTutorViews(state.allMttStudents, sortBy, order);
        state.sortingMttStudents = true;
    },
    /** Reducer Action to reset boolean state */
    resetMttStudentsAction: state => {
      state.loadingMttStudents = false;
      state.newInterventionCommentAdded = false;
      state.errorLoadingMttStudents = false;
      state.sortingMttStudents = false;
    },
     
    /** Reducer Action when a failure occurs */   
    getMttStudentsActionFailure: state => {
      state.loadingMttStudents = false;
      state.errorLoadingMttStudents = true;
    },
    
    
  },
});

export const { 
    initializeMttStudentsAction, 
    updateMttStudentListAction,
    addMttStudentInterventionCommentAction,
    sortMttStudentListAction,
    resetMttStudentsAction, 
    getMttStudentsActionFailure
} = mandatoryTargetedTutoringSlice.actions;
export const mandatoryTargetedTutoringSelector = state => state.mandatoryTargetedTutoringStudents;
export default mandatoryTargetedTutoringSlice.reducer;
