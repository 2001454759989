export interface ProficiencyJson {
    proficiencyScore: number;
    meetsCount: number;
    exceedsCount: number;
    approachingCount: number;
    developingCount: number;
}

export class Proficiency {

    public static fromJson = (proficiency: ProficiencyJson) => {
        if (proficiency) {
            return new Proficiency(
                proficiency.proficiencyScore,
                proficiency.meetsCount,
                proficiency.exceedsCount,
                proficiency.approachingCount,
                proficiency.developingCount
            );
        }
        return new Proficiency();
    }

    constructor(
        public proficiencyScore: number = -1,
        public meetsCount: number = -1,
        public exceedsCount: number = -1,
        public approachingCount: number = -1,
        public developingCount: number = -1
    ) {}
}