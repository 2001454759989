import React, { useEffect, useState } from 'react';
import './Shared.css';
import { GradebookEntry } from '../../models/GradebookEntry';
import { IMttInterventions, INewComment, ISkills } from '../../models/MandatoryTargetedTutoring';
import { MttStatus, MttParentView, IMttSessionStartWeek } from '../../models/enums/Mtt';

import {Course} from '../../models/Course';
import { mttSessionIsActive, getDatesTimes, convertInterventionsFromServerResponse } from "../../utils/helpers/mttHelpers";
import Container from '@mui/material/Container';
import { PanelTitleTypes, PanelFlyoutHeader } from '../layout/PanelTitleBar';
import { MttCommentCard } from '../inputs-elements/MttCommentCard';
import { ExitMttIcon } from '../../components/icons/CustomIcons';
import { UpdateInterventionDialog } from '../layout/UpdateInterventionDialog';

import { useDispatch, useSelector} from "react-redux";
import { User } from "../../store/session/types";
import { updateNotificationsAction, NotificationsType } from "../../store/notifications/notificationsReducer";
import { addInterventionCommentAction, enrollOrSkipInterventionAction, getGradebooksActionFailure,gradebooksSelector,updateAndExitMttAction, updateMttEligibiltyAction, updateGradebooksAction } from "../../store/student/gradebookReducer";
import { addMttStudentInterventionCommentAction } from "../../store/student/mandatoryTargetedTutoringReducer";

import { enrollOrSkipIntervention, addStudentInterventionComment, exitStudentOutOfIntervention, editStudentActiveIntervention, getStudentInterventions, continueStudentActiveIntervention } from '../../api/interventionApi';
import {  getCurrentWeekDate } from '../../api/globalApi';

import { SupervisedUserCircle } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import TabbedContentContainer from '../layout/TabbedContentContainer';
import moment from 'moment';
import { readableDateTimeFormat } from '../../common/config';
import { MttInterventionHeader } from './MttInterventionHeader';
import { LoadingIcon } from '../../utils/LoadingIcon';
import FeatureGate from '../../FeatureGate';
import { FeatureFlag } from '../../store/featureflags/types';
import { globalSelector } from "../../store/global/globalReducer";

const steps = [
    'Start / Dismiss Mtt Session',
    'Active Session',
    'Exited Session',
];

interface IMttDetails {
    selectedStudentGradebook: GradebookEntry;
    course:Course;
    currentUser:User;
    onCompletion: () => void;
    addCommentToMttView?: (newData: INewComment) => void;
    parentView: MttParentView;
    featureFlags:FeatureFlag[];
}

enum MttButtonType {
    Continue = "Continue",
    Edit = "Edit",
    Exit = "Exit",
}

export const MttDetailsPanel = ( { selectedStudentGradebook, course, currentUser, onCompletion, addCommentToMttView, parentView,featureFlags }:IMttDetails) => {
    const dispatch = useDispatch();
    const [exitDialogOpen, setExitDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [dataIsLoading, setDataIsLoading] = useState(true);
    const [selectedSessionWeek, setSelectedSessionWeek] = useState({selectedSessionDate:'', selectedSessionStatus: ''});
    const [selectedSession, setSelectedSession] = useState<IMttInterventions | undefined>(undefined);
    const [allInterventions, setAllInterventions] = useState<IMttInterventions[]>([]);
    const [mttSelectOptions, setMttSelectOptions] = useState<IMttSessionStartWeek[]>([]);
    const { allGradebooks } = useSelector(gradebooksSelector);
    const { termIsEditable, currentWeekTaskId, isFinalWeekMode } = useSelector(globalSelector);
    const [todaysWeekTaskId, setTodaysWeekTaskId] = useState<number>(-1);

    const teacherName:string = course.teacherName !== null ? course.teacherName : course.teacherDisplay !== null ? course.teacherDisplay : '';
    const currentUserName:string =`${currentUser.lastName},${currentUser.firstName}`;
    const studentName:string = `${selectedStudentGradebook.student.lastName},${selectedStudentGradebook.student.firstName}`;
    const studentId:number = selectedStudentGradebook.student.personId;
    const sectionId:number = selectedStudentGradebook.student.sectionId;
    const studentStatus:MttStatus = selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus;
    
    const skillsList: Array<string> = selectedStudentGradebook.assessment.standards.filter((standardItem) => {
        const result = standardItem.assignments.some((assignment) => {
            return assignment.isHomework === false;
        });
        return result;
    }).map(element => {
        return element.standardName
    });

    const { startWeek,startWeekDate, startDate, currentDate, currentDateISO } = getDatesTimes();

    const showHistoryByDefault = !termIsEditable ? true : 
        ((studentStatus !== MttStatus.ActiveMtt) &&
        (studentStatus !== MttStatus.ActiveWCommentsMtt) &&
        (studentStatus !== MttStatus.EligibleToExit) &&
        (studentStatus !== MttStatus.EligibleMtt)) ? true : false;

    const studentDroppedExitedMtt:boolean = selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus === MttStatus.ExitedMtt && 
    selectedStudentGradebook.student.isDropped ? true : false;
    
    useEffect(() => {
        // Fetch all past interventions whenever a new gradebook is loaded
        GetAllInterventions();   
    }, [selectedStudentGradebook]);

    const onSelectedSessionChange = (event) => {
        const selectedSessionWeek = event.target.value;
        const [date,status] = selectedSessionWeek.split(',');
        setSelectedSessionWeek( {selectedSessionDate:date, selectedSessionStatus: status});
        const selectedSession:IMttInterventions | undefined = allInterventions.find((session)=>{
            return session.startWeek === date;
        }); 
        setSelectedSession(selectedSession);
    };

    async function EnrollOrSkipMtt(mttStatus:MttStatus, commentText:string,skills?:string[]) {
        // TechDebt: https://dev.azure.com/StevensonIT/IRC/_workitems/edit/280 #3
        const response = await enrollOrSkipIntervention([{
                interventionId:0,
                studentPersonId:selectedStudentGradebook.student.personId,
                sectionId:selectedStudentGradebook.student.sectionId,
                status:mttStatus,
                startDate:startDate,
                startWeek:startWeekDate,
                skippedMttTaskID: mttStatus === MttStatus.SkippedMtt ? todaysWeekTaskId : null,
                initialComment:commentText ? commentText : undefined,
                interventionSkills:skills ? skills.map((skill:string) => { return { skillsOption:skill } }) : [],
                createdDate: startDate,
            }]).catch(error => {
            dispatch(updateNotificationsAction({notificationStatus:NotificationsType.Error, notificationMessage: 'Error Updating Students Mtt Status'}));
            dispatch(getGradebooksActionFailure);
        });
        
        if (response) {
            dispatch(updateNotificationsAction({notificationStatus:NotificationsType.Success, notificationMessage: 'Student Mtt Status Updated'}));
            dispatch(enrollOrSkipInterventionAction([{
                studentId:selectedStudentGradebook.student.personId,
                currentStudentStatus:mttStatus,
                mttInterventions: {
                    currentSessionStatus: mttStatus,
                    startWeek:startWeek,
                    startDate:startDate,
                    weeklyGrowthScore: selectedStudentGradebook.assessment.weeklyGrowth,
                    comments: [],
                    skills:  skills ? skills.map((skill:string) => { return { skillsOption: skill } }) : [],
                    initialComment: commentText ? commentText : '',
                }
            }]));
            if(onCompletion) onCompletion();
        }
    }

    async function GetAllInterventions() {
        setDataIsLoading(true);
        const responseWeek = await getCurrentWeekDate().catch(error => { /* Fail Silently, Log Error*/ });
        const todaysWeekTaskId:number = responseWeek ? responseWeek.data.taskId : currentWeekTaskId;
        setTodaysWeekTaskId(todaysWeekTaskId);

        if ((studentId !== -1) && (course.courseId !== -1)) {
            const response = await getStudentInterventions(studentId, [sectionId]).catch(error => {
                dispatch(updateNotificationsAction({notificationStatus:NotificationsType.Error, notificationMessage: 'Error getting Students interventions'}));
                dispatch(getGradebooksActionFailure);
                if(onCompletion) onCompletion();
            });

            if (response) {
                // TO Do // Create function for convert reponse 
                const allInterventions:IMttInterventions[] = convertInterventionsFromServerResponse(response, selectedStudentGradebook);
                
                const initialSession = allInterventions.length ? allInterventions.reduce((prevIntervention, currentIntervention, index) => {
                    if (index === 0) {
                        return currentIntervention;
                    } else if (currentIntervention.currentSessionStatus === MttStatus.ActiveMtt) {
                        return currentIntervention;
                    } else {
                        return prevIntervention;
                    }
                }) : undefined;
                
                const mttSessionsStartWeeks: Array<IMttSessionStartWeek> = allInterventions.map((session,index) => {
                    return {
                        selectedSessionDate: session.startWeek,
                        selectedSessionStartWeekNum: session.startWeekNum ? session.startWeekNum : '', 
                        selectedSessionStatus: session.currentSessionStatus
                    };
                });
    
                setAllInterventions(allInterventions);
                
                // Tech Debit this should be removed. but the intevention comment reducer should not check for interventions.
                // https://dev.azure.com/StevensonIT/IRC/_workitems/edit/386
                const updatedGradebooks = allGradebooks.map((gradebookItem, index) => {
                    if(studentId && (gradebookItem.student.personId === studentId)) {
                        const updatedGradebook ={
                            ...gradebookItem,
                            mandatoryTargetedTutoring: {
                                ...gradebookItem.mandatoryTargetedTutoring,
                                mttInterventions: [...allInterventions]
                            }
                        }
                        return updatedGradebook;
                    }
                    return gradebookItem;
                });
                dispatch(updateGradebooksAction(updatedGradebooks));

                // Update eligibilty status on front-end only. 
                if(studentStatus === MttStatus.ActiveWCommentsMtt){
                    dispatch( updateMttEligibiltyAction([{ studentId:studentId, mttStatus: MttStatus.ActiveMtt }]));
                }
                
                // Update component state
                if (initialSession) {
                    setSelectedSessionWeek( {selectedSessionDate:initialSession.startWeek, selectedSessionStatus: initialSession.currentSessionStatus});
                    setSelectedSession(initialSession);
                }
                setMttSelectOptions(mttSessionsStartWeeks);
                setDataIsLoading(false);
            }
        }
    }

    async function addInterventionComments(newCommentText:string, studentHasAttended:boolean | undefined) {
        const response = await addStudentInterventionComment({
            interventionId: selectedSession?.interventionId,
            commentDate: currentDateISO,
            personId: currentUser.personId,
            relationshipType: currentUser.roles[0].toString(),
            comments: newCommentText,
            attendance: studentHasAttended ? studentHasAttended : false,
        }).catch(error => { 
            dispatch(updateNotificationsAction({notificationStatus:NotificationsType.Error, notificationMessage: 'Error Updating Student Comments'}));
            if(onCompletion) onCompletion();
        });
        
        if (response) {
            dispatch(updateNotificationsAction({notificationStatus:NotificationsType.Success, notificationMessage: 'Comments Updated'}));
            const newData: INewComment = { studentId: studentId, newComment: {
                comments: newCommentText,
                commentAuthorName: `${currentUser.lastName}, ${currentUser.firstName}`,
                personId:currentUser.personId,
                commentDate:currentDateISO,
                interventionId: selectedSession?.interventionId,
                attendance: studentHasAttended ? studentHasAttended : false,
            }};

            // Update Active Students List found in mandatoryTargetedTutoringReducer
            if (parentView === MttParentView.MandatoryTargetedTutoringView) {
                dispatch(addMttStudentInterventionCommentAction(newData));
            }

            // Update Gradebooks Store found in gradebookReducer
            if (parentView === MttParentView.GradebookView) {
                dispatch(addInterventionCommentAction(newData));
            }
            
            if(onCompletion) onCompletion();
        }
    }

    function ExitOutOfMtt(exitCommentText:string) {
        const interventionID:number = (selectedSession && selectedSession.interventionId) ? selectedSession.interventionId : 0;        
        exitStudentOutOfIntervention(interventionID, {
            exitComment: exitCommentText
        }).then(response => {
            if (response && response.request.status === 200) {
                dispatch(
                    updateNotificationsAction({
                        notificationStatus:NotificationsType.Success, 
                        notificationMessage: 'Student successfully exited out of MTT'
                    }));
                dispatch(
                    updateAndExitMttAction({
                        studentId:selectedStudentGradebook.student.personId,
                        newComment: {
                            interventionId: selectedSession?.interventionId,
                            comments: exitCommentText,
                            commentAuthorName: currentUserName,
                            personId: currentUser.personId,
                            commentDate: currentDate,
                            isExitComment:true
                        }
                    }));                    
                setExitDialogOpen(false);
                if(onCompletion) onCompletion();
            }
        }).catch(error => {
            dispatch(updateNotificationsAction({notificationStatus:NotificationsType.Error, notificationMessage: 'Error Exiting Student Out of MTT'}));
        });
    }

    async function EditActiveMtt(updatedCommentText:string,updatedSkills:string[] | undefined) {
        const interventionID:number = (selectedSession && selectedSession.interventionId) ? selectedSession.interventionId : 0;
        const skillOptions:ISkills[] = updatedSkills ? updatedSkills.map((skill:string) => ({ skillsOption: skill }) ) : (selectedSession ? selectedSession.skills.map((skill:ISkills) => ({ skillsOption: skill.skillsOption })) : [] )
        
        const response = await editStudentActiveIntervention(interventionID, {
            interventionId: interventionID,
            interventionSkills:skillOptions,
            initialComment:updatedCommentText,
        }).catch(error => {
            dispatch(updateNotificationsAction({notificationStatus:NotificationsType.Error, notificationMessage: 'Error updating MTT Intervention'}));
        });
        if (response) {
            dispatch(
                updateNotificationsAction({
                    notificationStatus:NotificationsType.Success, 
                    notificationMessage: `Successfully edited student's MTT Intervention`
                }));
            setEditDialogOpen(false);
            if(onCompletion) onCompletion();
        }
    }

    async function ContinueActiveMtt() {
        const interventionID:number = (selectedSession && selectedSession.interventionId) ? selectedSession.interventionId : 0;
        const response = await continueStudentActiveIntervention(interventionID, {
            interventionId: interventionID,
            continuedMttTaskID: todaysWeekTaskId
        }).catch(error => {
            dispatch(updateNotificationsAction({
                notificationStatus:NotificationsType.Error, notificationMessage: `Error continuing student's Intervention`
            }));
        });
        if (response) {
            dispatch(
                updateNotificationsAction({
                    notificationStatus:NotificationsType.Success, 
                    notificationMessage: `Successfully continued student's Intervention`
                }));
            dispatch(
                updateMttEligibiltyAction([{
                    studentId:selectedStudentGradebook.student.personId,
                    mttStatus: MttStatus.ActiveMtt
                }]));
            setEditDialogOpen(false);
            onCompletion();
        }
    }
    
    const renderHeaderDetails = (selectedStudentGradebook?:GradebookEntry):JSX.Element => {
        if ((selectedStudentGradebook === undefined) || (selectedStudentGradebook.student.personId === -1) || mttSessionIsActive(selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus)) {
            return (
                <>
                    {(selectedStudentGradebook && selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus === MttStatus.EligibleToExit) && (
                        <Typography variant="caption" display="block" gutterBottom sx={{marginBottom:'14px'}}>
                            <NotificationImportantIcon fontSize='small' color='warning' /> 
                            {studentName} is eligible to be exited out of the current Intervention. 
                        </Typography>
                    )}
                   
                    <MttInterventionHeader
                        selectedSessionWeek={selectedSessionWeek}
                        onSelectedSessionChange={onSelectedSessionChange}
                        mttSelectOptions={mttSelectOptions}
                        selectedSession={selectedSession}
                    />
                </>
            )
        } else {
            return (
                <Typography variant="caption" display="block" gutterBottom>
                    <NotificationImportantIcon fontSize='small' color='warning' /> 

                    {selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus === MttStatus.NotEligible ? (
                        <>
                            {studentName} is not eligible for MTT Intervention.
                        </>
                    ) : (
                        <>
                            {studentName} has a <strong> {selectedStudentGradebook.assessment.weeklyGrowth}</strong>. 
                            Should this student be added to MTT?
                        </>
                    )}
                    
                </Typography>
            );
        }
    }

    const mttPanelContent = (selectedStudentGradebook: GradebookEntry, isHistoryPanel?: boolean): JSX.Element => {        
        return isHistoryPanel && (allInterventions.length === 0) ? 
        (
            <MttCommentCard
                key={'1'} 
                subheader={'No Intervention History'} 
                mttStatus={selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus} 
                isCommentReadOnly={true}                                              
            />
        ) : (
            <>
                <Grid item xs={12}>
                    {renderHeaderDetails(isHistoryPanel ? undefined : selectedStudentGradebook)}                    
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: '0 -24px',padding:'10px 24px'  }}>
                        <Typography component="h6">Details</Typography>
                    </Box>
                    {isHistoryPanel || (selectedStudentGradebook.mandatoryTargetedTutoring && mttSessionIsActive(selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus)) ?
                        (
                            <>
                                {/* Only allow adding session notes on an active intervention */}
                                {!isHistoryPanel && selectedSession && 
                                (selectedSession.currentSessionStatus === MttStatus.ActiveMtt) &&
                                FeatureGate("CanAddMTTComment", featureFlags)     
                                    && (
                                        <MttCommentCard 
                                            title='' 
                                            subheader='' 
                                            commentTitle='Please add session notes' 
                                            mttStatus={selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus} 
                                            saveFunction={(newCommentText,undefined,hasAttended ) => addInterventionComments(newCommentText, hasAttended)}
                                            currentUser={currentUser}
                                            isActiveSessionComment
                                        />
                                    )
                                }
                                
                                {(selectedSession && selectedSession.comments.length) ?
                                    selectedSession.comments.map((comment, commentIndex) => {   
                                        return (
                                            <MttCommentCard
                                                key={commentIndex} 
                                                title={comment.commentAuthorName ?  comment.commentAuthorName : ''}
                                                subheader={comment.commentDate ?  moment(comment.commentDate).format(readableDateTimeFormat) : ''} 
                                                commentText={comment.comments} 
                                                mttStatus={selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus} 
                                                isCommentReadOnly={true}
                                                isActiveSessionComment
                                                hasAttended={comment.attendance ? comment.attendance : false}                                                
                                            />
                                        );
                                    }) : ''
                                }
                            </>
                        ) : (
                            FeatureGate("CanUpdateMttSession", featureFlags) && 
                            selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus !== MttStatus.NotEligible
                            ? (
                                // If Student is not in an active Session and User Role has access to Assign a new MTT session show the following
                                <>
                                    <MttCommentCard 
                                        title='Yes, add student to MTT' 
                                        subheader='Please add skills that need to be reviewed*' 
                                        commentTitle='Please add comments*' 
                                        skillSelect
                                        skillsSelectItems={skillsList}
                                        mttStatus={MttStatus.EligibleMtt}
                                        saveFunction={(commentText:string,skills?:string[]) => EnrollOrSkipMtt(MttStatus.ActiveMtt,commentText,skills)} 
                                    />
                                    {selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus === MttStatus.EligibleMtt &&
                                        (
                                            <MttCommentCard 
                                                title='No, this student does not require MTT assistance' 
                                                commentTitle='Please add comments*' 
                                                mttStatus={MttStatus.EligibleMtt}
                                                saveFunction={(commentText:string) => EnrollOrSkipMtt(MttStatus.SkippedMtt,commentText)} 
                                            />
                                        ) 
                                    }
                                </>
                            ) : (
                                // If Student is not in an active Session and User Role does not have CanUpdateMttSession feature
                                <MttCommentCard
                                    key={'1'} 
                                    subheader={'Permission needed to assign MTT Intervention Or Student is not eligible for MTT Intervention'} 
                                    mttStatus={selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus} 
                                    isCommentReadOnly={true}                                              
                                />

                            )
                            
                        )
                    }
                </Grid>
            </>
        )
    }

    const hideTabs = (
        (selectedSession && mttSessionIsActive(selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus)) ||      
        (!dataIsLoading && allInterventions.length === 0) || 
        !termIsEditable // user is viewing data from a past term        
    ) ? true : false;

    const editMttActionsDisabled: boolean = !selectedSession || (selectedSession && (selectedSession.currentSessionStatus !== MttStatus.ActiveMtt)) ||  !FeatureGate("CanUpdateMttSession", featureFlags) || !termIsEditable;

    const getMttButton = (buttonType: MttButtonType, isDisabled: boolean): JSX.Element => {
        let button: JSX.Element = <></>;
        let toolTipTitle: string = ``;
        switch (buttonType) {
            case MttButtonType.Continue:
                toolTipTitle = selectedSession?.continuedMttTaskID === todaysWeekTaskId ?
                                                                        `${studentName} has already been continued this week. Close Panel.` :
                                                                            (selectedSession?.continuedMttTaskID === null && studentStatus !== MttStatus.EligibleToExit) ? 
                                                                                `Close Panel.`: 
                                                                                `Continue ${studentName} in current Mtt intervention session`;
                button = (
                    <Button 
                        startIcon={<CachedIcon sx={{ fontSize: '26px !important' }}/>} 
                        aria-label="Continue Mtt" 
                        size="medium" 
                        onClick={() => (
                            (selectedSession?.continuedMttTaskID == todaysWeekTaskId ) && studentStatus !== MttStatus.EligibleToExit) ? 
                                onCompletion() : 
                                    studentStatus !== MttStatus.EligibleToExit ? onCompletion() : ContinueActiveMtt()
                        }
                        disabled={isDisabled}   
                    />
                );
                break;
            case MttButtonType.Edit:
                toolTipTitle = `Edit current Mtt intervention session`;
                button = (
                    <Button 
                        startIcon={<EditIcon sx={{ fontSize: '26px !important' }}/>} 
                        aria-label="Edit MTT" 
                        size="large"
                        onClick={()=> setEditDialogOpen(true)}
                        disabled={isDisabled}
                    />
                );
                break;
            case MttButtonType.Exit:
                toolTipTitle = `Exit ${studentName} out of current Mtt intervention session`;
                button = (
                    <Button 
                        startIcon={<ExitMttIcon color="primary" 
                            fontSize="large" 
                            sx={{ fontSize: '40px !important' }}/>} 
                        aria-label="Exit MTT"
                        onClick={()=> setExitDialogOpen(true)}
                        sx={{'&.Mui-disabled svg': {color: '#b7b7b7 !important'}}}
                        disabled={isDisabled}
                    />
                );
                break;
            default: 
                // Do nothing
                break;
        }
        return isDisabled ? button : <Tooltip title={toolTipTitle} arrow>{button}</Tooltip>;
    }

    return (
        <>
        <PanelFlyoutHeader
            mainTitle={dataIsLoading ? '' : selectedStudentGradebook.student.courseName}
            mainSubTitle={dataIsLoading ? '' : ''}
            secondaryTitle={dataIsLoading ? '' : studentName}
            secondarySubTitle={dataIsLoading ? '' : selectedStudentGradebook.assessment.projectedGrade}
            isFinal={false}
            teacherArray={dataIsLoading ? [] : [teacherName, course.teacherEmail, course.teacherPhone]}
            toolBarType={PanelTitleTypes.Primary}
            showWarningIcon={false} 
        />
        <Container maxWidth="md" sx={{ width: '100%' }}>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                sx={{ marginLeft: `-24px`, marginRight: `-24px`, width: `calc(100% + 48px)` }}
            >
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between', padding: '10px 24px'}}>
                    <Typography sx={{ alignItems: 'center', display: 'flex'}} variant="h6" gutterBottom component="div">
                        <SupervisedUserCircle sx={{ fontSize: 30, margin: '0 10px 0 0' }}  color='info'/> 
                        Mandatory Targeted Tutoring
                    </Typography>
                    
                    
                    { mttSessionIsActive(selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus) && (
                        editMttActionsDisabled ? (
                            // Show Action Toolbars if student is in active mtt session, but disable if selected session is not active or not current term
                            <div style={{display: 'flex' }}>
                                {getMttButton(MttButtonType.Continue, true)}
                                {getMttButton(MttButtonType.Edit, true)}
                                {getMttButton(MttButtonType.Exit, true)}
                            </div>
                        ) : (
                            <ButtonGroup 
                                variant="text" 
                                aria-label="text button group"
                            >
                                {getMttButton(MttButtonType.Continue, false)}
                                {getMttButton(MttButtonType.Edit, false)}
                                {getMttButton(MttButtonType.Exit, false)}
                            </ButtonGroup>
                        )
                    )}
                    {/* Exit Comment Dialog */}
                    <UpdateInterventionDialog 
                        title='Exit out of Mandatory Targeted Tutoring' 
                        subheader={`Do you wish to exit ${studentName} from Mandatory Targeted Tutoring Intervention?`}
                        commentPlaceholder='Comments'
                        submitBtnText='Yes, Exit out of MTT'
                        cancelBtnText='No, Continue MTT'
                        isOpen={exitDialogOpen}
                        cancelFunction={() => setExitDialogOpen(false)}
                        saveFunction={(exitComment:string)=> ExitOutOfMtt(exitComment)}
                    />

                    {/* Edit MTT Dialog */}
                    <UpdateInterventionDialog 
                        title='Edit Mandatory Targeted Tutoring Intervention' 
                        commentPlaceholder='Enrollment Comment:'
                        commentValue={(selectedSession && selectedSession.initialComment) ? selectedSession.initialComment : ''}
                        skillsList={skillsList}
                        selectedSkills={(selectedSession && selectedSession.skills.length) ? selectedSession.skills.map((skill:ISkills) => { return skill.skillsOption }) : []}
                        submitBtnText='Update'
                        cancelBtnText='Cancel'
                        isOpen={editDialogOpen}
                        cancelFunction={() => setEditDialogOpen(false)}
                        saveFunction={(updatedComment:string, updatedSkills:string[] | undefined) => EditActiveMtt(updatedComment, updatedSkills)}
                    />
                </Grid>
                { dataIsLoading ? 
                    <LoadingIcon text='Loading Intervention Details' /> 
                    : hideTabs ? (
                            <div style={{ margin: `0 24px 48px` }}>
                                {termIsEditable ? mttPanelContent(selectedStudentGradebook) : mttPanelContent(selectedStudentGradebook, true)}
                            </div>
                        ) : (
                            <TabbedContentContainer 
                                title={'Mandatory Targeted Tutoring Tabs'}
                                content={studentDroppedExitedMtt || isFinalWeekMode ? 
                                        [
                                            {
                                                tabName: "History",
                                                tabContent: mttPanelContent(selectedStudentGradebook, true)
                                            }
                                        ] : 
                                        [
                                            {
                                                tabName: "Enroll / Skip",
                                                tabContent: mttPanelContent(selectedStudentGradebook)
                                            },
                                            {
                                                tabName: "History",
                                                tabContent: mttPanelContent(selectedStudentGradebook, true)
                                            }
                                        ]
                                }
                                defaultTab={
                                    showHistoryByDefault ? 
                                        studentDroppedExitedMtt || isFinalWeekMode ? 0 : 1 : 0             
                                }
                            />
                        )
                }         
            </Grid>
        </Container>
        <Paper sx={{ position: 'fixed', bottom: 0, width: '560px'}} elevation={3}>
            <Stepper 
                style={{padding: '10px 20px'}} 
                activeStep={
                    mttSessionIsActive(selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus) ? 
                    (selectedSession && (selectedSession.currentSessionStatus === MttStatus.ActiveMtt) ? 1 : 2 ) : 
                    (selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus === MttStatus.ExitedDroppedMtt) || (selectedStudentGradebook.mandatoryTargetedTutoring.currentStudentStatus === MttStatus.ExitedMtt) ? 2 : 0
                }
            >
                { termIsEditable ? (
                    steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))
                ) : ''}
            </Stepper>
        </Paper>
        
      </>
    );
  };