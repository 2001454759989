
import { http } from 'utils/helpers/apiHelpers'

/**  Request to get the current week*/
export const getCurrentWeekDate = async (termId:number = 0): Promise<any> => {
    return await http.get<any>(`mtt/CurrentWeek/${termId}`); 
};

/**  Request to get the list of predefined comment options to be used within the weeky growth score comments*/
export const getWGCommentOptions = async (): Promise<any> => {
    return await http.get<any>(`mtt/GradingComments`);
};
