import * as React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const ExitMttIcon = createSvgIcon(<><path d="m18.828 8.8-.981.981 1.8 1.8h-7.082v1.392h7.078l-1.8 1.8.981.988 3.48-3.48ZM3.085 5.734h5.567V4.342H3.085a1.4 1.4 0 0 0-1.392 1.392v12.533a1.4 1.4 0 0 0 1.392 1.392h5.567v-1.392H3.085Z"/><path d="M6.005 6.85a5.241 5.241 0 0 0-3.28 1.18c-1.081.837-.67 2.312-.67 3.97a41.99 41.99 0 0 1-.1 3.17 5.062 5.062 0 0 0 4.05 1.98 5.15 5.15 0 1 0 0-10.3Zm1.863 3.265a.994.994 0 1 1-.994.994.991.991 0 0 1 .994-.994Zm-3.09-.814a1.215 1.215 0 1 1-1.215 1.215 1.218 1.218 0 0 1 1.216-1.215Zm0 4.7v1.931a4.123 4.123 0 0 1-2.646-2.552 4.32 4.32 0 0 1 2.647-.87 5.238 5.238 0 0 1 .978.113 1.556 1.556 0 0 0-.978 1.38Zm1.227 2.118a3.528 3.528 0 0 1-.407-.021v-2.1c0-.731 1.514-1.1 2.266-1.1a3.651 3.651 0 0 1 1.977.592 4.129 4.129 0 0 1-3.836 2.629Z"/></>,
  'ExitMttIcon',
);

export const GoogleIcon = createSvgIcon(<svg xmlns="http://www.w3.org/2000/svg" width="46" height="46"><g fill="none"><g filter="url(#a)" transform="translate(3 3)"><use fill="#FFF"/><use/><use/><use/></g><path fill="#4285F4" d="M31.64 23.205c0-.639-.057-1.252-.164-1.841H23v3.481h4.844a4.14 4.14 0 0 1-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615Z"/><path fill="#34A853" d="M23 32c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711h-3.007v2.332A8.997 8.997 0 0 0 23 32Z"/><path fill="#FBBC05" d="M17.964 24.71a5.41 5.41 0 0 1-.282-1.71c0-.593.102-1.17.282-1.71v-2.332h-3.007A8.996 8.996 0 0 0 14 23c0 1.452.348 2.827.957 4.042l3.007-2.332Z"/><path fill="#EA4335" d="M23 17.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C27.463 14.891 25.426 14 23 14a8.997 8.997 0 0 0-8.043 4.958l3.007 2.332c.708-2.127 2.692-3.71 5.036-3.71Z"/><path d="M14 14h18v18H14V14Z"/></g></svg>,
  'GoogleIcon',
);
 
