import React , { useEffect,useState }from 'react';
import {Course} from '../../models/Course';
import { TeacherStudent } from '../../models/TeacherStudent';
import Period from '../../models/Period';
import { DropDown } from '../inputs-elements/DropDown';
import { GradebookEntry, IStudentGradeBook } from '../../models/GradebookEntry';
import { FlexRow, FlexCol, TextOverflow }  from '../layout/FlexHelpers';
import PanelTitleBar, { PanelTitleTypes, PanelChildLeft,  PanelChildRight} from '../layout/PanelTitleBar';
import { isFinalsWeek } from "utils/helpers/generalHelpers";
import { WeekType } from 'models/WeeklyGrowth';
import HoverModal, {  HoverModalTypes, HoverModalContent } from '../layout/HoverModal';
import ProjectedGradeLetter from '../layout/ProjectedGradeLetter';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import './Shared.css';
import { ISelectableItem } from '../inputs-elements/ActionBar';
import Checkbox from '@mui/material/Checkbox';
import { FeatureFlagState } from '../../store/featureflags/types';
import FeatureGate from '../../FeatureGate';
import { connect, useSelector } from 'react-redux';
import { globalSelector } from "../../store/global/globalReducer";
import { showFinalLabel } from 'utils/helpers/stringHelpers';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export interface StudentSelectStateProps {  
  features: FeatureFlagState;
}   

export interface StudentSelectCardProps {
  course: Course;
  grades: GradebookEntry[];
  onPeriodChanged: Function;
  onSorting: Function;
  onStudentSelect: Function;
  periods: Period[];
  selectableGradebookEntries: Array<ISelectableItem<IStudentGradeBook>>;
  selectedPeriod: number;
  selectedStudent?: TeacherStudent;
  selectionChange: (selections: Array<ISelectableItem<IStudentGradeBook>>) => void;
  sortOrder: boolean;
}
 
const StudentSelectCard = (props: StudentSelectCardProps) => {
  const features = (state) => state.featureFlags;
  const activeFeatures = useSelector(features);
  const { termIsEditable, currentWeekHideProjectedGrade, currentWeekTaskId, viewingWeekType  } = useSelector(globalSelector);
 
  const icon = renderIcon();
  const teacherName: string = props.course.teacherName !== null ? props.course.teacherName : props.course.teacherDisplay !== null ? props.course.teacherDisplay : '';
 
  const showCheckBox = (isDirectGradeStudent:boolean): boolean => {
    if(!isDirectGradeStudent)
      return true;
    if(isDirectGradeStudent && viewingWeekType == WeekType.FinalWeek)
      return false;
    return true;
  }



  return(
      <>
          <PanelTitleBar toolBarType={PanelTitleTypes.Secondary}>
            <PanelChildLeft>
                {renderHeaderDisplay(teacherName, props.course.courseName, icon, props.course.teacherEmail, props.course.teacherPhone)}
            </PanelChildLeft>
            <PanelChildRight>
              {renderPeriodDropdown(props.periods, props.selectedPeriod, props.onPeriodChanged)}
            </PanelChildRight>
          </PanelTitleBar>

          {props.selectedStudent && 
            <div className="courseListContainer">
              <table className='table table-striped gradebookTable' aria-labelledby="tabelLabel">
                <thead className="gradebookTableHeaderRow"> 
                  <tr>
                    <th style={{width:'56px'}}>&nbsp;</th>
                    <th style={{ minWidth: '120px', fontSize:'13px'}}>
                      <a onClick={ () => props.onSorting() } style={{cursor:'pointer', display: 'block' }}>
                        Student Name 
                        {props.sortOrder ? (<ArrowDropUpIcon/>) : (<ArrowDropDownIcon/>)}
                      </a>
                    </th>
                    {viewingWeekType !== WeekType.FinalWeek && (
                      <th style={{width:'86px', fontSize:'13px'}} >
                        {!currentWeekHideProjectedGrade && (
                          <FlexRow justifyContent='flex-end' flexDirection='column'>
                              <FlexCol textAlign='center'>
                                <HoverModal title='Projected Grade' type={HoverModalTypes.Third} content={HoverModalContent.projectedgrade}/>
                              </FlexCol>
                          </FlexRow>
                        )}
                      </th>
                    )}
                    
                  </tr>
                </thead>
                <tbody>
                  {props.grades.map((grade: GradebookEntry)=>{  
                      const isChecked = props.selectableGradebookEntries.some(sgbe => sgbe.selected && sgbe.id === grade.student.personId.toString());
                      const selectedStudentItem:IStudentGradeBook = { 
                          studentPersonId:grade.student.personId,
                          sectionId:grade.assessment.sectionID,
                          weeklyGrowthScore: grade.assessment.weeklyGrowth,
                          projectedGrade: grade.assessment.projectedGrade,
                          finalGrade: grade.assessment.finalGrade,
                          currentMTTStatus:grade.mandatoryTargetedTutoring.currentStudentStatus,
                          taskId: currentWeekTaskId
                        }; 
                      return (
                        <tr className={`studentRow`} key={grade.student.personId}>
                          <td>
                            {
                              (
                                !grade.student.isDropped &&
                                showCheckBox(grade.student.directGradeEligible) &&
                                FeatureGate("CanEditWeeklyGrowthScore", activeFeatures.featureFlags) && 
                                termIsEditable
                              ) && 
                                (
                                  <Checkbox 
                                    checked={isChecked}
                                    onChange={(e) => {
                                      props.selectionChange([{ id: grade.student.personId.toString(), selected: isChecked, item: selectedStudentItem }])                        
                                    }}
                                  />
                                )
                            }
                          </td>
                          <td onClick={(e)=> props.onStudentSelect(grade)}>
                            <div className="gradebookTableRow" style={{display:'inline'}}>                      
                              {grade.student.lastName}, {grade.student.firstName}
                            </div>
                          </td>

                          {viewingWeekType !== WeekType.FinalWeek && (
                            <td onClick={(e)=> props.onStudentSelect(grade)}>
                              <div className="gradebookTableRow" style={{display:'flex', justifyContent:'center', height:'40px', alignItems:'center'}}>                            
                                <ProjectedGradeLetter parentRange={currentWeekHideProjectedGrade ? '-' : grade.assessment.projectedGrade} />
                              </div>
                            </td>
                          )}

                        </tr>
                      )
                    }
                  )}
                </tbody>
              </table>
            </div>
          }
      </>
  )
}


const renderPeriodDropdown = (periods: Period[], selectedPeriod: number, onPeriodSelect: Function) => {

  return <DropDown 
  textColor='#ffffff' 
  isPeriod items={periods} 
  selectedItem={selectedPeriod} 
  text="periodName"
  subTitle="courseNumber" 
  description={"courseName"} 
  closeOnMouseExit={true} 
  onSelect={onPeriodSelect} 
  idPath="periodId"/>     
}

const renderIcon = () => {
    return <ContactMailIcon/>
}

const renderHeaderDisplay = (teacherName: string, courseName: string, icon: JSX.Element, teacherEmail: string, teacherPhone: string) =>{
 
    return (
      <>
          <TextOverflow>
            {courseName}
          </TextOverflow>
          <HoverModal 
            title={teacherName} 
            type={HoverModalTypes.Teacher} 
            preventHeadlineWrap={true}
            teacherContactInfo={{
              email: teacherEmail,
              phone: teacherPhone
            }}
          />
      </>
    );

}
 
function mapStateToProps(state: any): StudentSelectStateProps{
  return {
    features: state.featureFlags,
  };
}
export default connect<StudentSelectStateProps>(mapStateToProps)(StudentSelectCard);