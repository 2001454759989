import React, { useState, useEffect } from 'react';
import {
  useLocation
} from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { IMenuListItems } from 'models/Menus';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FeatureFlagState } from 'store/featureflags/types';
import { connect, useSelector } from 'react-redux';
import FeatureGate from '../../FeatureGate';
import { globalSelector } from "store/global/globalReducer";
import { GradeStatus, SkillStatus } from 'models/Grade';
import { WeekType } from 'models/WeeklyGrowth';
import { RouteTypes } from 'models/enums/Routes';
import { isFinalsWeek } from "utils/helpers/generalHelpers";
import { isSkillType } from "utils/helpers/skillsHelpers";
import { ScoreNumbers } from 'models/enums/Standards';
import WarningIcon from '@mui/icons-material/Warning';
import RemoveIcon from '@mui/icons-material/Remove';
import { convertScoreToInitial } from 'utils/helpers/stringHelpers';
import Badge from '@mui/material/Badge';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
 

interface IMenuDropDown {
  menuTitle: string;
  buttonText?: string;
  secondaryButtonText: string;
  disableButton?:boolean;
  menuId: string;
  menuItems: IMenuListItems[];
  suggestedProficiencyScore?: number;
  updateSkillProficiencyFunction: (skillProficiencyOverideId:number) => void;
  viewSkillsFunction?: () => void;
}

const EditSkillProficiencyMenu = ({ menuTitle, buttonText, secondaryButtonText, disableButton, menuId, menuItems, suggestedProficiencyScore, updateSkillProficiencyFunction, viewSkillsFunction }: IMenuDropDown) => {

  //#region Variables:
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();
  const [isGradebook, setIsGradebook] = useState(false);
  const open = Boolean(anchorEl);
  const features = (state) => state.featureFlags;
  const activeFeatures = useSelector(features);
  const { termIsEditable, isFinalWeekMode, currentWeekTaskId, viewingWeekType} = useSelector(globalSelector);
  const canEditSkills = FeatureGate("CanEditWeeklyGrowthScore", activeFeatures.featureFlags) && 
                          termIsEditable && 
                          isGradebook && 
                          (isFinalsWeek(currentWeekTaskId) || isFinalWeekMode) && 
                          viewingWeekType === WeekType.FinalWeek &&
                          (disableButton !== true);

  const scoreOverWritten = buttonText === convertScoreToInitial(suggestedProficiencyScore ? suggestedProficiencyScore.toLocaleString() : '') ? false : true;
  const theme = useTheme();
  //#endregion Variables:

  //#region Hooks:

  useEffect(() => {
    if( location.pathname === RouteTypes.GradeBookRoute)
      setIsGradebook(true);
  }, [location]);
  //#endregion Hooks:

  //#region Functions:
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onUpdateWGScoreClick = (skillProficiencyOverideId:number): void => {
    updateSkillProficiencyFunction(skillProficiencyOverideId);
    closeMenu();
  };

  const viewSkillsClick = (): void => {
    if (viewSkillsFunction) {
      viewSkillsFunction();
    }
    closeMenu();
  };
  //#endregion Functions:
 
 
  return canEditSkills ? (
    <>
      <Button
        variant="contained"
        color="secondary"
        style={{ color: "#212529", fontSize: "1.2rem", position: "absolute", zIndex: "200",height: "36px" }}
        id={`menu-button-${menuId}`}
        aria-controls={open ? `menu-${menuId}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(event: any) => openMenu(event) }
        disabled={(disableButton ? disableButton : false) || (buttonText === GradeStatus.LoadingGrade ? true : false)}
 
      >
        <Tooltip title={scoreOverWritten ? 'Suggested skill proficiency is overwritten.' : ''} placement="right" enterDelay={800}>
          <Badge 
            variant="dot"  
            invisible={!scoreOverWritten}
            sx={{
              '.MuiBadge-badge':{
                color: '#fff',
                backgroundColor: theme.palette.gold.main,
              }
            }}
          >
            {buttonText ? (
              (buttonText === GradeStatus.LoadingGrade || buttonText === SkillStatus.LoadingSkill.toLocaleString()) ? (
                  <CircularProgress size={19} color="inherit" />
                ) : buttonText === ScoreNumbers.Five ? (
                  <WarningIcon color='warning'/>
                ) : buttonText === ScoreNumbers.Zero ? (
                  <RemoveIcon color='info'/>
                ) : buttonText
            ) : !termIsEditable ? (
              <LaunchIcon color="info" fontSize="small" />
            ) : (
              ""
            )}

          </Badge>
        </Tooltip>
      </Button>
      <Menu
        id={`menu-${menuId}`}
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": `menu-button-${menuId}`,
        }}
        style={{ maxHeight: "580px" }}
      >
        <MenuList sx={{ width: "100%", maxWidth: 360, minWidth: 280, bgcolor: "background.paper" }} dense>
          {menuTitle ? <MenuItem disabled>{menuTitle}</MenuItem> : null}
          {menuItems.length ? (
            menuItems.map((item: IMenuListItems, index) => {
              return (
                <ListItem
                
                  key={index}
                  secondaryAction={
                    isSkillType(buttonText ? buttonText : '',item.primaryText) && (
                      <IconButton edge="end" aria-label="Suggested Skill Proficiency">
                        <CheckBoxIcon color='primary'/>
                      </IconButton>
                    ) 
                  }
                  disablePadding
                >
                  <ListItemButton onClick={() => onUpdateWGScoreClick(item.id ? item.id : -1)} selected={isSkillType(buttonText ? buttonText : '',item.primaryText)}>
   
                    <ListItemText 
                      id={index.toString()} 
                      primary={`${item.primaryText}`}
                      secondary={ suggestedProficiencyScore && item.id === suggestedProficiencyScore ? `Suggested` : `` }  
                    />
                  </ListItemButton>
                </ListItem>
              );
            })
          ) : (
            <ListItem key={"1"} disabled>
              <ListItemText id={"missing-options"} primary={`No skills options available`} />
            </ListItem>
          )}

          {/* Secondary Menu Actions  */}
          <Divider style={{marginTop:'15px'}}/>

          {secondaryButtonText && (
            <MenuItem onClick={viewSkillsClick} style={{ marginTop: "15px" }}>
              <Typography variant="body2" color="text.secondary" style={{ marginRight: "10px" }}>
                <LaunchIcon fontSize="small" />
              </Typography>
              {secondaryButtonText}
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </>
  ) : (
    <Button
        color="secondary"
        style={{ color: "#212529", fontSize: "1.3rem", position: "absolute", zIndex: "1",height: "100%", width: "100%" }}
        id={`menu-button-${menuId}`}
        onClick={(event: any) => viewSkillsClick() }
        disabled={(disableButton ? disableButton : false) || (buttonText === GradeStatus.LoadingGrade ? true : false)}
      >
        {buttonText ? (
          buttonText === GradeStatus.LoadingGrade ? (
            <CircularProgress size={19} color="inherit" />
          ) : buttonText === ScoreNumbers.Five ? (
            <Typography variant="subtitle2" sx={{ color: 'alert.main' }}>
              Incomplete
            </Typography>
          ) : buttonText === ScoreNumbers.Zero ? (
            <RemoveIcon color='info'/>
          ) : buttonText
        ) : !termIsEditable ? (
          <LaunchIcon color="info" fontSize="small" />
        ) : (
          ""
        )}
    </Button>
  );
};
export interface EditWeeklyGrowthStoreProps {  
  features: FeatureFlagState;
}   
function mapStateToProps(state: any): EditWeeklyGrowthStoreProps{
  return {
    features: state.featureFlags,
  };
}
export default connect<EditWeeklyGrowthStoreProps>(mapStateToProps)(EditSkillProficiencyMenu);