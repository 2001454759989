import React, { useEffect, useState  } from 'react';
import { SessionState } from '../store/session/types';
import { connect, useSelector,useDispatch } from 'react-redux';
import { FeatureFlagState } from '../store/featureflags/types';
import FeatureGate from '../FeatureGate';
 
import PanelTitleBar, { PanelTitleTypes, PanelChildLeft,  PanelChildRight } from './layout/PanelTitleBar';
import HoverModal, {  HoverModalTypes, HoverModalContent } from './layout/HoverModal';
import { TextOverflow }  from './layout/FlexHelpers';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import { DropDown } from './inputs-elements/DropDown';
import { DropDownStudents } from './inputs-elements/DropDownStudents';
import {LoadingIcon} from '../utils/LoadingIcon';
import { Row, Col } from './layout/ResponsiveGrid';
import { Redirect } from 'react-router';
import {Course} from '../models/Course';
import {Term} from '../models/Term';
import { Student } from '../models/Student';
import { GradebookEntry } from '../models/GradebookEntry';
import { TeacherStudent } from '../models/TeacherStudent';
import { Standard } from '../models/Standard';

import StandardsReportCard from './shared/StandardsReportCardView';
import StudentSelectReportCard from './shared/StudentSelectReportCardView';
import CourseSelectCardReportCard from './shared/CourseSelectCardReportCard';
import WeeklyGrowthReportCard from './shared/WeeklyGrowthReportCardView';
import HomeworkReportCard from './shared/HomeworkReportCardView';
import { PrintableReportCard } from './PrintableReportCard';
import { SearchTeacherStudent,SearchType } from './inputs-elements/SearchTeacherStudent';
 
import { ProjectedGradeInitials } from './layout/ProjectedGradeInitials';
import { getActiveAssignments, hasMissingActiveAssignments,isFinalsWeek } from '../utils/helpers/generalHelpers';
import { MttStatus } from '../models/enums/Mtt';
import { getStudentInterventions } from '../api/interventionApi';
import { IMttInterventions } from '../models/MandatoryTargetedTutoring';
import { convertInterventionsFromServerResponse } from '../utils/helpers/mttHelpers';
import { InterventionHistoryReportCard } from './shared/InterventionHistoryReportCardView';
import { globalSelector, updateIsFinalWeekModeAction, updateCurrentWeekDateAction} from "store/global/globalReducer";
import { getCurrentWeekDate } from 'api/globalApi';
import { axiosResponseValid } from 'utils/helpers/apiHelpers'; 
import { IWeekDate } from '../models/WeekDate';
import  { gaEvent } from 'utils/helpers/analyticsHelpers';
import { UserRole } from 'store/session/types';
import { FeatureFlagType } from "models/enums/FeatureFlag";
import { isUserType } from 'utils/helpers/permissionsHelpers';
import { LocalStorageType } from 'models/enums/LocalStorage';


import axios from "axios";
 


export enum PanelTypes{
  WeeklyPanel = 'weeklygrowthpanel',
  StandardsPanel = 'standardspanel',
  HomeworkPanel = 'homeworkpanel',
  MttInterventionHistory = 'mttInterventionHistory',
  NonePanel = '',
}

export enum MobileSteps{
  Step_1 = 'Step1_Course_lists',
  Step_2 = 'Step2_Course_hidden_Student_summary_shown',
  Step_3 = 'Step3_Student_summary_hidden_Active_shown',
  Step_4 = 'Step4',
}

export interface ReportCardState {
    activeCourse: Course;
    activeStudent?: Student;
    activeStudentGradebook: GradebookEntry;
    activeStudentStandard: Standard;
    allInterventions: Array<IMttInterventions>;
    canSearchStudents?: boolean;
    children: Student[];
    courses: Course[];
    fetchInitiated: boolean;
    fetchingStudentsInitiated?: boolean;
    gradeBookList: GradebookEntry[];
    isCollapsed: boolean;
    isCourseSelected: boolean;
    isHomeworkFiltering: boolean;
    isMobile: boolean; 
    loading: boolean;
    mobileSteps: MobileSteps;
    secondaryLoader: boolean;
    selectedTerm?: Term;
    selectedTermIndex: number;
    showPrintableReportCard: boolean;
    studentList?: Array<Student>;
    terms: Term[];
    viewingPanel: PanelTypes;
}


export interface ReportCardStateProps {
    session: SessionState;
    features: FeatureFlagState;
}
 
type ownReportCardProps = {}
 

export const ReportCard = ({ReportCardStateProps}) => {

  const dispatch = useDispatch();
  const session = (state) => state.session;
  const features = (state) => state.featureFlags;
  const activeSession = useSelector(session);
  const activeFeatures = useSelector(features);

  const { isFinalWeekMode, userTypes, currentWeekHideProjectedGrade} = useSelector(globalSelector);
 
  const [state, setState] = useState<ReportCardState>({
    activeCourse: new Course(),
    activeStudent: undefined,
    activeStudentGradebook:new GradebookEntry(),
    activeStudentStandard: new Standard(),
    allInterventions: [],
    canSearchStudents: false,
    children:[],
    courses:[],
    fetchInitiated: false,
    fetchingStudentsInitiated: false,
    gradeBookList:[],
    isCollapsed: false,
    isCourseSelected: false,
    isHomeworkFiltering: false,
    isMobile: false,
    loading: true, 
    mobileSteps: MobileSteps.Step_1, 
    secondaryLoader:false, 
    selectedTerm: undefined, 
    selectedTermIndex: 0, 
    showPrintableReportCard: false,
    studentList: [],
    terms: [], 
    viewingPanel: PanelTypes.NonePanel,
  });
  const teacherName: string = state.activeCourse.teacherName !== null ? state.activeCourse.teacherName : state.activeCourse.teacherDisplay !== null ? state.activeCourse.teacherDisplay : '';
  const hasMissingAssignment:boolean = hasMissingActiveAssignments(getActiveAssignments(state.activeStudentGradebook));
  const hasVerifiedFinalGrade:boolean = state.activeStudentGradebook.assessment.finalGrade && state.activeStudentGradebook.assessment.isVerified ? true : false;
  const hasAdminRole:boolean = isUserType(userTypes,[FeatureFlagType.admin]);

  //#region Effects
  // Get and Set and active term
  useEffect(() => {
    let isSubscribed = true;
    window.addEventListener("resize", detectResize.bind(this));
    detectResize();
    if(activeSession.isLoggedIn && isSubscribed){  
      getTerms();
      if (activeSession.user.roles.length && activeSession.user.roles.some(r => r === UserRole.Student )){
        gaEvent.studentReportCardView(activeSession.user.personId, activeSession.user.lastName + ',' + activeSession.user.firstName);    
      }

      //
    }
    return () => {
      isSubscribed = false;
    };
  }, [activeSession]);

  // Get selected term latest week, if final then dispatch final week mode action
  // If selected term has an actual final week then this will force the application into final week mode.
  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      const response = await getCurrentWeekDate(state.selectedTerm ? state.selectedTerm.termId : -1).catch(error => { /* Fail Silently, Log Error*/ });

      if (axiosResponseValid(response)) {
        const latestSemesterWeek:IWeekDate = response.data;
        const localStorageFinalWeekMode = localStorage.getItem(LocalStorageType.FinalWeekMode);

        if(isFinalsWeek(latestSemesterWeek.taskId)){
          dispatch(updateIsFinalWeekModeAction(true));
        }else if(!hasAdminRole){
          // Not admin and not Actual Final week
          dispatch(updateIsFinalWeekModeAction(false));
        }else if(hasAdminRole && !localStorageFinalWeekMode){
          // Admin but not set to final week mode
          dispatch(updateIsFinalWeekModeAction(false));
        }

        const newActiveWeek:IWeekDate = {...latestSemesterWeek};
        dispatch(updateCurrentWeekDateAction(newActiveWeek));
      }
    }
    if(activeSession.isLoggedIn && isSubscribed && state.terms.length && state.selectedTerm){
      fetchData().catch(console.error);
    }
    return () => {
      isSubscribed = false;
    };
  }, [activeSession, state.terms, state.selectedTerm]);

  // Once an active term is updated
  useEffect(() => {
    let isSubscribed = true;

    const newActiveStudent: Student = {
      parentID: -1,
      childID: -1,
      currentIdentityID: parseInt(activeSession.user.userId),
      firstName: activeSession.user.firstName,
      lastName: activeSession.user.lastName,
      personId: parseInt(activeSession.user.personId),
      staffNumber: -1,
      studentNumber: -1
    } 
    if(FeatureGate("CanAccessReportCards", activeFeatures.featureFlags) && isSubscribed && state.selectedTerm) {            
        if (FeatureGate("Parent", activeFeatures.featureFlags) && !state.activeStudent){    
          getChildren();
        } else if(FeatureGate("Student", activeFeatures.featureFlags) && !state.activeStudent){
 
          getStudentCourses(newActiveStudent);       
        }   
    }
    
    return () => {
      isSubscribed = false;
    };
  }, [activeSession,activeFeatures,state.selectedTerm,state.terms,state.activeStudent]); 






  // If Admin get student list for student search
  useEffect(() => {
    let isSubscribed = true;
    if (FeatureGate("CanSearchStudents", activeFeatures.featureFlags) && isSubscribed && (state.studentList?.length === 0) && state.selectedTerm ){
      getStudentList();
    }
    return () => {
      isSubscribed = false;
    };
  }, [activeSession,activeFeatures,state.studentList,state.selectedTerm]);

  // If Parent or Admin now that children have been updated get the active student courses
  useEffect(() => {
    let isSubscribed = true;
    if(FeatureGate("CanAccessReportCards", activeFeatures.featureFlags) && state.activeStudent && (state.activeStudent.studentNumber !== -1) && state.selectedTerm && isSubscribed) {            
        if (FeatureGate("Parent", activeFeatures.featureFlags) || FeatureGate("CanSearchStudents", activeFeatures.featureFlags)){ 
          getStudentCourses(state.activeStudent);
        }  
    }
    return () => {
      isSubscribed = false;
    };
  }, [activeSession,activeFeatures, state.activeStudent,state.selectedTerm]);


  // If Child / Student has courses then get interventions if any
  useEffect(() => {
    let isSubscribed = true;
    if(state.courses.length && state.activeStudent && (state.activeStudent.studentNumber !== -1) && isSubscribed) {          
      FetchInterventions(state.activeStudent.personId);
    }
    return () => {
      isSubscribed = false;
    };
  }, [state.courses,state.activeStudent]);


  // If I as a Student has courses then get interventions if any
  useEffect(() => {
    let isSubscribed = true;
    if(state.courses.length && FeatureGate("Student", activeFeatures.featureFlags) && activeSession.user.personId && isSubscribed) {          
      FetchInterventions(activeSession.user.personId);
    }

    return () => {
      isSubscribed = false;
    };
  }, [state.courses,activeSession,activeFeatures ]);



  
  //#endregion Effects
  
  //#region Funtions
  const togglePrintableReportCard = () => {

    setState(prevState => ({
      ...prevState,showPrintableReportCard: !state.showPrintableReportCard}));

    if(!state.showPrintableReportCard){
      gaEvent.studentReportCardPrinted(
        activeSession.user.personId, 
        activeSession.user.lastName + ',' + activeSession.user.firstName,
        state.selectedTerm?.termId
      );    
    }   
  }

  const detectResize = () => {
      const currentHideNav = (window.innerWidth <= 760);
      if (currentHideNav !== state.isMobile) {
          setState(prevState => ({
            ...prevState,isMobile: currentHideNav})); 
      }
  }

  const renderTermDropdown = (terms: Term[], selectedTerm: number, onTermSelect: Function) => {
      return <div className='termDropdown' style={{ marginTop: '10px'}}><DropDown items={terms} selectedItem={selectedTerm} text="termName" onSelect={onTermSelect} idPath="termId" closeOnMouseExit={true}/></div>     
  }

  const renderChildrenDropdown = (children: Student[], selectedDropdownItem: number, onChildSelect: Function) => {
      return <DropDownStudents 
        items={children} 
        selectedItem={selectedDropdownItem} 
        displayPath="firstName" 
        onSelect={onChildSelect} 
        idPath="personId" 
        closeOnMouseExit={true}
        />     
  }

  const SelectedCourseChanged = (course: Course): void => {    
    const correctGradebookList: GradebookEntry[] = [...state.gradeBookList];
    let correctGradebook:any = new GradebookEntry();

    if (correctGradebookList.some( elem => elem.assessment !== null && (elem.assessment.sectionID === course.sectionId))) {
      correctGradebook = correctGradebookList.find( elem => elem.assessment !== null && (elem.assessment.sectionID === course.sectionId));
    }

    if (state.isMobile) {
      setState(prevState => ({
        ...prevState,isCourseSelected: true, activeCourse: course, activeStudentGradebook: correctGradebook, viewingPanel: PanelTypes.NonePanel, mobileSteps: MobileSteps.Step_2 }));
    } else {
      setState(prevState => ({...prevState,isCourseSelected: true, activeCourse: course, activeStudentGradebook: correctGradebook, viewingPanel: PanelTypes.NonePanel }));
    }

    if  (activeSession.user.roles.length && activeSession.user.roles.some(r => r === UserRole.Student )){
        gaEvent.studentReportCardCourse(
          activeSession.user.personId, 
          activeSession.user.lastName + ',' + activeSession.user.firstName,
          course.courseName,
          course.courseId,
          state.selectedTerm?.termId
        );    
    }
  } 

  const _onWeeklySelect = (selectedGradeEntry: GradebookEntry, breadCrumbNav?: boolean): void => {
    setState(prevState => ({
      ...prevState,
      viewingPanel: PanelTypes.WeeklyPanel,
      activeStudentGradebook: selectedGradeEntry,
      mobileSteps: MobileSteps.Step_3 
    }));
  }


  const _onStandardSelect = (studentGradeEntry: GradebookEntry, selectedStandard: Standard, breadCrumbNav?: boolean): void => {
    setState(prevState => ({
      ...prevState, 
      viewingPanel: PanelTypes.StandardsPanel,
      activeStudentGradebook: studentGradeEntry,
      activeStudentStandard: selectedStandard,
      mobileSteps: MobileSteps.Step_3 
    }));
    
  }

  const _onHomeworkSelect = (selectedGradeEntry: GradebookEntry, filtering: boolean, breadCrumbNav?: boolean): void => {
    setState(prevState => ({
      ...prevState, 
      viewingPanel: PanelTypes.HomeworkPanel,
      activeStudentGradebook: selectedGradeEntry,
      isHomeworkFiltering: filtering,
      mobileSteps: MobileSteps.Step_3
    }));
  }

  const _onInterventionHistorySelect = (): void => {
    setState(prevState => ({
      ...prevState, 
      viewingPanel: PanelTypes.MttInterventionHistory,
      mobileSteps: MobileSteps.Step_3
    }));
    if  (activeSession.user.roles.length && state.allInterventions.length && activeSession.user.roles.some(r => r === UserRole.Student )){
      const interventionIds:number[] = state.allInterventions
                                  .filter((i: IMttInterventions) => i.sectionId === state.activeCourse.sectionId)
                                  .map((intervention:IMttInterventions) => intervention.interventionId ? intervention.interventionId : -1) ?? [];
      gaEvent.studentReportCardInterventionViewed(
        activeSession.user.personId, 
        activeSession.user.lastName + ',' + activeSession.user.firstName,
        state.activeCourse.courseName,
        state.activeCourse.courseId,
        interventionIds,
        state.selectedTerm?.termId
      );    
    }
  }

  const _onCoursesBackSelect = (): void => {
    setState(prevState => ({
      ...prevState, 
      viewingPanel: PanelTypes.NonePanel,
      mobileSteps: MobileSteps.Step_1,
      isCourseSelected: false,
      activeStudentGradebook:new GradebookEntry(),
      activeCourse: new Course() 
    }));
  }

  const _onOverviewBackSelect = (): void => {
    setState(prevState => ({
      ...prevState,
      viewingPanel: PanelTypes.NonePanel,
      mobileSteps: MobileSteps.Step_2 
    }));
  }
  
  const dropdownNumber = (fullStandardList:Student[] , selectedChild: Student | undefined ): number => {
      if (selectedChild) {
          for(let i = 0; i < fullStandardList.length; i++) {
              if(Number(fullStandardList[i].personId) === Number(selectedChild.personId)) {
                  return i;
              }
          }
      }
      return -1; 
  }

  const renderHeaderDisplay = (teacherName: string, courseName: string, icon: JSX.Element, teacherEmail: string, teacherPhone: string) =>{
      return (
      <>
          <TextOverflow>
              {courseName}
          </TextOverflow>
          <HoverModal 
              title={teacherName} 
              type={HoverModalTypes.Teacher} 
              teacherContactInfo={{
              email: teacherEmail,
              phone: teacherPhone
              }}
          />
      </>
      );
  }
  const renderIcon = () => {
    return <ContactMailIcon/>
  }
  //#endregion Funtions

  //#region Async Functions
  async function getStudentList(){ 
    if ((FeatureGate("CanSearchStudents", activeFeatures.featureFlags) || FeatureGate("CanSearchTeachers", activeFeatures.featureFlags)) && !state.fetchingStudentsInitiated) {
        setState(prevState => ({
          ...prevState,
            fetchingStudentsInitiated: true
        }));
        fetch(`student/searchbystudent`, 
        {
            method: 'GET',
            headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json'
            },
            credentials: 'include'}
        ).then((response) => response.json())
        .then((responseJson) => {
            
            const studentList: Student[] = responseJson;

            setState(prevState => ({
              ...prevState,
                loading: false,
                fetchingStudentsInitiated: false,
                studentList: studentList,
                canSearchStudents: true
            }));
        
        }).catch(function (error) {
            console.error(error);
        });
    }
}

async function FetchInterventions(studentId: number){ 
    if (state.courses.length) {
        const response = await getStudentInterventions(studentId, state.courses.map((c: Course) => c.sectionId), MttStatus.SkippedMtt.toString()).catch(error => {
          console.error(error);              
        });
        if (response) {
          const allInterventions:IMttInterventions[] = convertInterventionsFromServerResponse(response, state.activeStudentGradebook);

          setState(prevState => ({
            ...prevState,
            allInterventions,
          }));
        }         
    } 
}

async function getChildren(){

    setState(prevState => ({
      ...prevState,
      fetchInitiated: true,
    }));

    await fetch(`student/parent?pid=${activeSession.user.personId}`,
    {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'}
    ).then((response) => response.json())
    .then((responseJson) => {
      
      const children: Student[] = responseJson;
      const sortedChildren: Student[] = children.sort(function(a, b) {
          const textA = a.firstName.toUpperCase();
          const textB = b.firstName.toUpperCase();
          return textA.localeCompare(textB);
      });

      setState(prevState => ({
        ...prevState,
          children: sortedChildren,
          activeStudent: sortedChildren[0],
          loading: false
      })); 
        
    }).catch(function (error) {
      console.error(error);
    });
}
 

// Get Courses for the Active Student  
async function getStudentCourses(s: any) {
 
  if (s) {
        let student: Student;
        if (s?.firstName) { 
            student = s;
        } else { 
            student = s.value;
        }

        if((student !== undefined) && (student.personId !== undefined) && state.selectedTerm !== undefined){
        
          setState(prevState => ({ ...prevState,secondaryLoader: true })); 

 
          const coursesList: Course[] = await fetch(`course/student?pid=${student.personId}&cid=${state.selectedTerm.calendarId}&tid=${state.selectedTerm.termId}`,
          {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include'
          })
          .then(response => {
            const data = response.json();
            return data;
          });

       
          const uniqueCourses = coursesList.filter( (course, ind) => ind === coursesList.findIndex( elem => elem.sectionId === course.sectionId));
  
          const response = await Promise.all(
            uniqueCourses.map((course) =>  axios.get(`student/gradebookbystudent?sid=${course.sectionId}&pid=${student.personId}&isEBR=${course.ebrFlag}&termId=${state.selectedTerm ? state.selectedTerm.termId : 0}${isFinalWeekMode ? `&isFinalWeekMode=true` : ``}`))
          ).then(response => {
            return response;
          });

 
          const childGradeBookList: GradebookEntry[] = [];
          
          response.map((gradebook:any)=>{
            childGradeBookList.push({
                    student: new TeacherStudent(),
                    assessment: gradebook.data.assessment,
                    weeklyGrowth: gradebook.data.weeklyGrowth,
                    mandatoryTargetedTutoring: { currentStudentStatus:MttStatus.EligibleMtt, mttInterventions: [] }
            })  
          });
    
          setState(prevState => ({
              ...prevState, 
            loading: false,
            secondaryLoader: false,
            courses: uniqueCourses,
            gradeBookList:childGradeBookList, 
            isCourseSelected: false,
            viewingPanel: PanelTypes.NonePanel 
          }));
 
 

      }
    } 
}

async function getTerms(){
    await fetch(`course/terms`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
    .then(response => { return response.json() })
    .then(response => response as Term[])
    .then(terms => {
      if(terms.length > 0){
        const term = terms[0];
        setState(prevState => ({
          ...prevState,terms: terms.sort(x=>x.termStart.valueOf()) , selectedTerm: term}));
      }

    }).catch(function (error) {
      console.error(error);
    });
}

async function onTermChanged(selectedTermId: number){
    const terms: Term[] = state.terms;
    for(let i = 0; i < terms.length; i++){
      if(terms[i].termId == selectedTermId){
        setState(prevState => ({
          ...prevState,selectedTermIndex: i, selectedTerm: terms[i]}));
      }
    }
}
//#endregion Async Functions

const icon:JSX.Element = renderIcon();
const selectedChildNumber: number = dropdownNumber(state.children, state.activeStudent);
 
return !activeSession.isLoggedIn ? (
  <Redirect to="/login" />
) : state.loading ? (
  <LoadingIcon text="Loading Report Card, please wait ..." />
) : state.showPrintableReportCard ? (
  <PrintableReportCard
    allInterventions={state.allInterventions}
    courses={state.courses}
    gradebookEntries={state.gradeBookList}
    student={state.activeStudent ? state.activeStudent : new Student()}
    term={state.selectedTerm}
    togglePrintableReportCard={togglePrintableReportCard}
    hideProjectedGrade={currentWeekHideProjectedGrade}
  />
) : (
  <>
    <Row fullHeight style={{ alignContent: "start" }}>
      <Col xs={12} md={state.isCollapsed ? 0.5 : 3} border>
        <PanelTitleBar toolBarType={PanelTitleTypes.Fifth}>
          <Row fullHeight style={{ alignContent: "start", height: "auto" }}>
            <Col xs={12} style={{ textAlign: "right" }}>
              {FeatureGate("Parent", activeFeatures.featureFlags) && renderChildrenDropdown(state.children, selectedChildNumber, getStudentCourses.bind(this))}
            </Col>
            <Col xs={12} style={{ textAlign: "right" }}>
              {state.terms.length && renderTermDropdown(state.terms, state.selectedTermIndex, onTermChanged.bind(this))}
            </Col>
          </Row>
        </PanelTitleBar>

        {state.isMobile ? (
          // Mobile Step_1
          state.mobileSteps == MobileSteps.Step_1 && (
            <CourseSelectCardReportCard
              terms={state.terms}
              courses={state.courses}
              activeCourse={state.activeCourse}
              onCourseSelect={SelectedCourseChanged}
              allGradeBookList={state.gradeBookList}
              activeInterventions={state.allInterventions.filter((i: IMttInterventions) => i.currentSessionStatus === MttStatus.ActiveMtt)}
              selectedTerm={0}
              isLoading={state.secondaryLoader}
              selectedTermChanged={onTermChanged.bind(this)}
              isParentCollapsed={false}
              togglePrintableReportCard={togglePrintableReportCard}
              hideProjectedGrade={currentWeekHideProjectedGrade}
            >
              {(FeatureGate("CanSearchStudents", activeFeatures.featureFlags) || FeatureGate("CanSearchTeachers", activeFeatures.featureFlags)) && (
                <SearchTeacherStudent
                  loading={state.loading}
                  searchType={SearchType.Student}
                  selectedStudent={state.activeStudent}
                  selectedStudentChanged={(student: any) => {
                    // blank student on default
                    let incomingStudent: Student = {
                      parentID: -1,
                      childID: -1,
                      currentIdentityID: -1,
                      firstName: "",
                      lastName: "",
                      personId: -1,
                      staffNumber: -1,
                      studentNumber: -1,
                    };
                    if (student !== null) {
                      //Update blank student to actual student
                      incomingStudent = {
                        parentID: -1,
                        childID: -1,
                        currentIdentityID: -1,
                        firstName: student.value.firstName,
                        lastName: student.value.lastName,
                        personId: student.value.personId,
                        staffNumber: -1,
                        studentNumber: student.value.studentNumber,
                      };
                      setState((prevState) => ({ ...prevState, activeStudent: incomingStudent }));
                    } else {
                      setState((prevState) => ({ ...prevState, activeStudent: incomingStudent }));
                    }
                  }}
                  selectedTerm={0}
                  selectedTermChanged={onTermChanged.bind(this)}
                  studentList={state.studentList}
                  terms={state.terms}
                />
              )}
            </CourseSelectCardReportCard>
          )
        ) : (
          // Desktops || Tablets Always show
          <CourseSelectCardReportCard
            terms={state.terms}
            courses={state.courses}
            activeCourse={state.activeCourse}
            onCourseSelect={SelectedCourseChanged}
            allGradeBookList={state.gradeBookList}
            activeInterventions={state.allInterventions.filter((i: IMttInterventions) => i.currentSessionStatus === MttStatus.ActiveMtt)}
            selectedTerm={0}
            isLoading={state.secondaryLoader}
            selectedTermChanged={onTermChanged.bind(this)}
            isParentCollapsed={false}
            togglePrintableReportCard={togglePrintableReportCard}
            hideProjectedGrade={currentWeekHideProjectedGrade}
          >
            {(FeatureGate("CanSearchStudents", activeFeatures.featureFlags) || FeatureGate("CanSearchTeachers", activeFeatures.featureFlags)) && (
              <SearchTeacherStudent
                loading={state.loading}
                searchType={SearchType.Student}
                selectedStudent={state.activeStudent}
                selectedStudentChanged={(student: any) => {
                  // blank student on default
                  let incomingStudent: Student = {
                    parentID: -1,
                    childID: -1,
                    currentIdentityID: -1,
                    firstName: "",
                    lastName: "",
                    personId: -1,
                    staffNumber: -1,
                    studentNumber: -1,
                  };
                  if (student !== null) {
                    //Update blank student to actual student
                    incomingStudent = {
                      parentID: -1,
                      childID: -1,
                      currentIdentityID: -1,
                      firstName: student.value.firstName,
                      lastName: student.value.lastName,
                      personId: student.value.personId,
                      staffNumber: -1,
                      studentNumber: student.value.studentNumber,
                    };
                    setState((prevState) => ({ ...prevState, activeStudent: incomingStudent }));
                  } else {
                    setState((prevState) => ({ ...prevState, activeStudent: incomingStudent }));
                  }
                }}
                selectedTerm={0}
                selectedTermChanged={onTermChanged.bind(this)}
                studentList={state.studentList}
                terms={state.terms}
              />
            )}
          </CourseSelectCardReportCard>
        )}
      </Col>

      {/* Check to see if Course Selected First */}
      {state.isCourseSelected && !state.secondaryLoader && (
        <Col xs={12} md={3} border>
          <PanelTitleBar toolBarType={PanelTitleTypes.Sixth}>
            <PanelChildLeft>
              <>{renderHeaderDisplay(teacherName, state.activeCourse.courseName, icon, state.activeCourse.teacherEmail, state.activeCourse.teacherPhone)}</>
            </PanelChildLeft>
            {currentWeekHideProjectedGrade === false && (
              <PanelChildRight>
                <>
                  <HoverModal title={hasVerifiedFinalGrade ? "Final Grade" : "Projected Grade"} type={HoverModalTypes.Fourth} content={HoverModalContent.projectedgrade} />
                  <ProjectedGradeInitials
                    projectedGrade={hasVerifiedFinalGrade ? state.activeStudentGradebook.assessment.finalGrade : state.activeStudentGradebook.assessment.projectedGrade}
                    showWarningIcon={hasMissingAssignment}
                  />
                </>
              </PanelChildRight>
            )}
          </PanelTitleBar>

          {state.isMobile ? (
            <>
              {/* If step 2 for mobile  */}
              {state.mobileSteps == MobileSteps.Step_2 && (
                <StudentSelectReportCard
                  activeCourse={state.activeCourse}
                  allInterventions={state.allInterventions.filter((i: IMttInterventions) => i.sectionId === state.activeCourse.sectionId)}
                  breadCrumbNav={true}
                  isHomeworkFiltering={state.isHomeworkFiltering}
                  isReportCard
                  onCoursesBackSelect={_onCoursesBackSelect}
                  onHomeworkAllSelect={_onHomeworkSelect}
                  onInterventionHistorySelect={_onInterventionHistorySelect}
                  onStandardSelect={_onStandardSelect}
                  onWeeklySelect={_onWeeklySelect}
                  panelActiveType={state.viewingPanel}
                  selectedGradeBookEntry={state.activeStudentGradebook}
                  selectedStandardProps={state.activeStudentStandard}
                />
              )}
            </>
          ) : (
            // Desktops || Tablets
            <StudentSelectReportCard
              activeCourse={state.activeCourse}
              allInterventions={state.allInterventions.filter((i: IMttInterventions) => i.sectionId === state.activeCourse.sectionId)}
              isHomeworkFiltering={state.isHomeworkFiltering}
              isReportCard
              onHomeworkAllSelect={_onHomeworkSelect}
              onInterventionHistorySelect={_onInterventionHistorySelect}
              onStandardSelect={_onStandardSelect}
              onWeeklySelect={_onWeeklySelect}
              panelActiveType={state.viewingPanel}
              selectedGradeBookEntry={state.activeStudentGradebook}
              selectedStandardProps={state.activeStudentStandard}
            />
          )}
        </Col>
      )}

      {/* Show Selected Standard Growth Panel */}
      {state.viewingPanel == PanelTypes.WeeklyPanel && state.mobileSteps == MobileSteps.Step_3 && (
        <Col xs={12} md={6} border>
          <WeeklyGrowthReportCard
            selectedGradeBookEntry={state.activeStudentGradebook}
            activeCourse={state.activeCourse}
            breadCrumbNav={state.isMobile ? true : false}
            onStudentSelect={_onOverviewBackSelect}
            isReportCard
          />
        </Col>
      )}

      {/* Show Intervention History Panel */}
      {state.viewingPanel == PanelTypes.MttInterventionHistory && state.mobileSteps == MobileSteps.Step_3 && (
        <Col xs={12} md={6} border>
          <InterventionHistoryReportCard
            selectedGradeBookEntry={state.activeStudentGradebook}
            activeCourse={state.activeCourse}
            breadCrumbNav={state.isMobile ? true : false}
            onStudentSelect={_onOverviewBackSelect}
            interventionHistory={state.allInterventions.filter((i: IMttInterventions) => i.sectionId === state.activeCourse.sectionId)}
          />
        </Col>
      )}

      {/* Show Selected Standard Component Panel */}
      {state.viewingPanel == PanelTypes.StandardsPanel && state.mobileSteps == MobileSteps.Step_3 && (
        <Col xs={12} md={6} border>
          <StandardsReportCard
            selectedStandardProps={state.activeStudentStandard}
            selectedStudentGradebook={state.activeStudentGradebook}
            activeCourse={state.activeCourse}
            breadCrumbNav={state.isMobile ? true : false}
            onStudentSelect={_onOverviewBackSelect}
            onStandardsUpdated={_onStandardSelect}
            isReportCard
          />
        </Col>
      )}

      {/* Show Selected Homework Component Panel */}
      {state.viewingPanel == PanelTypes.HomeworkPanel && state.mobileSteps == MobileSteps.Step_3 && (
        <Col xs={12} md={6} border>
          <HomeworkReportCard
            activeCourse={state.activeCourse}
            breadCrumbNav={state.isMobile ? true : false}
            isFiltering={state.isHomeworkFiltering}
            onStudentSelect={_onOverviewBackSelect}
            selectedGradeBookEntry={state.activeStudentGradebook}
            isReportCard
          />
        </Col>
      )}
    </Row>
  </>
);
}

 
function mapStateToProps(state: any, ownProps: ownReportCardProps): ReportCardStateProps{
    return {
      session: state.session,
      features: state.featureFlags
    };
  }
  export default connect<ReportCardStateProps>(mapStateToProps)(ReportCard);