/* eslint-disable @typescript-eslint/no-empty-interface */
import * as React from 'react';
import './shared/Shared.css';
import { GradebookState, GradebookProps } from './Gradebook';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Logo from './layout/Logo';
import  { PanelChildLeft, PanelChildRight, PanelSubHeaderDisplay } from './layout/PanelTitleBar';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { MdArrowBack } from "react-icons/md"; 
import FlexLabel from './layout/FlexHelpers';
import { TeacherStudent } from '../models/TeacherStudent';
import AssignmentBar from './charts/AssignmentBar';
import { Assignment } from '../models/Assignment';
import { GradebookEntry } from '../models/GradebookEntry';
import StandardLineGraph from './charts/StandardLineGraph';
import { ProficiencyType } from '../models/enums/Standards';
import { showFinalLabel } from 'utils/helpers/stringHelpers';
import Typography from '@mui/material/Typography';

export interface IPrintableGradebookProps {
    gradebookProps: GradebookProps;
    gradebookState: GradebookState;
    togglePrintableGradebook: () => void;
    studentIds: Array<number>;
    hideProjectedGrade: boolean;
}

export interface IPrintableGradebookState {
}

export class PrintableGradebook extends React.Component<IPrintableGradebookProps, IPrintableGradebookState> {

    rowStyle: React.CSSProperties = {
        borderTop: 'thick solid #777', 
        borderRight: 'thin solid #777', 
        margin: 0,
        width:'100%'
    };
    colStyle: React.CSSProperties = {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'flex-start', 
        borderLeft: 'thin solid #777',        
        padding: '.5rem',
    };
    standardsColStyle: React.CSSProperties = {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'flex-start', 
        alignItems: 'stretch', 
        backgroundColor: '#f0f2f7',
        borderTop: 'thin solid #777',
        borderLeft: 'thin solid #777',
        padding: 0,
    };    
    standardsRowStyle: React.CSSProperties = {
        width: '100%',
        margin: 0
    };
    standardColStyle: React.CSSProperties = {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between', 
        alignItems: 'flex-start', 
        borderLeft: 0,
        padding: '.5rem',
    };

    componentDidMount() {
        window.print();
    }

    render() {
 
        const modifiedGradebook: GradebookEntry[] = this.props.gradebookState.grades.map(gradebookItem => {
            const result: GradebookEntry =  {...gradebookItem, assessment: {...gradebookItem.assessment, standards: [...gradebookItem.assessment.standards]}}
            return result;
        });
 
        let filteredGradebook: GradebookEntry[] = modifiedGradebook;
        if (this.props.studentIds.length) {
            filteredGradebook = modifiedGradebook.filter(gbe => this.props.studentIds.some(sid => sid === gbe.student.personId));
        }

        return (            
            <div style={{padding: '1rem 3.8rem' }}>
                <style>
                    {`@media print {
                        .MuiToolbar-root{display: none !important;}   
                        .navbar, .back-button {display: none;}
                        .studentRow {page-break-inside: avoid;}
                    }`}
                </style>
                {/* Page Header Row: Logo + Class/Teacher Info */}
                <Grid container spacing={2}  style={{margin: '0', width: '100%' }}>
                    <Grid item xs={9} md={9}>
                        <IconButton 
                            onClick={() => this.props.togglePrintableGradebook()} 
                            className={'back-button'}
                            style={{marginRight: '1rem', backgroundColor: '#ddd', color: '#fff', border: 0 }}
                        >
                            <MdArrowBack size='24' />
                        </IconButton>
                        <Logo textColor={"#275D38"} />
                    </Grid>
                    <Grid item xs={3} md={3} style={{display: 'flex', alignItems: 'center' }}>
                        <PanelChildLeft>
                            <PanelSubHeaderDisplay icon={<ContactMailIcon/>} subTitle={this.getTeacherName()} title={this.props.gradebookState.selectedCourse.courseName}/>
                        </PanelChildLeft>
                        <PanelChildRight>
                            <h6 style={{marginLeft: '1rem'}}>{this.props.gradebookState.selectedCourse.periodName}&nbsp;Period</h6>
                        </PanelChildRight>
                    </Grid>
                </Grid>

                <div style={{borderBottom: 'thin solid #777'}}>
                    {/* Table Header Row */}
                    <Grid container spacing={2} style={this.rowStyle}>
                        <Grid item xs={3} style={this.colStyle}>
                            <h6>Student Name</h6>
                        </Grid>
                        <Grid item xs={3} style={this.colStyle}>
                            {this.props.hideProjectedGrade == false && (<h6>{showFinalLabel(filteredGradebook)}</h6>)} 
                        </Grid>
                        <Grid item xs={3} style={this.colStyle}>
                            <h6>Wk G</h6>
                        </Grid>
                        <Grid item xs={3} style={this.colStyle}>
                            <h6>Practice/Preparation</h6>
                            <FlexLabel>
                                <span>Initial</span>
                                <span>Current</span> 
                            </FlexLabel>
                        </Grid>
                    </Grid>
                    {filteredGradebook.map((gradebookEntry, gradebookEntryIndex) => {
                        const hasVerifiedFinalGrade = gradebookEntry.assessment.finalGrade && gradebookEntry.assessment.isVerified ? true : false;
                        return (
                            <Grid container spacing={2} style={this.rowStyle} className={'studentRow'} key={gradebookEntryIndex}>
                                <Grid item xs={3} style={this.colStyle}>
                                    {this.getStudentName(gradebookEntry.student)}
                                </Grid>
                                <Grid item xs={3} style={this.colStyle}>

                                    {this.props.hideProjectedGrade == false && (<>
                                        {hasVerifiedFinalGrade ? (
                                            <Typography variant="h6" gutterBottom>
                                                {gradebookEntry.assessment.finalGrade}
                                            </Typography>
                                        ) : (
                                            <Typography variant="h6" gutterBottom>
                                                {gradebookEntry.assessment.projectedGrade}
                                            </Typography>
                                        )}
                                    </>)}

                                </Grid>
                                <Grid item xs={3} style={this.colStyle}>
                                    <div className="gradebookTableRow" style={{textAlign: 'center'}}>{gradebookEntry.assessment.weeklyGrowth}</div>
                                </Grid>
                                <Grid item xs={3} style={this.colStyle}>
                                    <AssignmentBar assignmentList={this.getHomeworkBoolean(gradebookEntry)} />
                                </Grid>
                                <Grid item xs={12} style={this.standardsColStyle}>
                                    <Grid container spacing={2} style={this.standardsRowStyle}>
                                        {gradebookEntry.assessment.standards.filter(s => s.assignments.some(a => a.isHomework === false)).map((standard, standardIndex) => {
                                            const proficiencyArray: Array<number> = [standard.proficiency.exceedsCount, standard.proficiency.meetsCount,standard.proficiency.approachingCount, standard.proficiency.developingCount];
                                            const graphLevelsArray: Array<string> = standard.assignments.filter(assignment => {
                                                return (assignment.score !== null) && (assignment.standardEventActive == 1);
                                            }).sort(function compare(assignmentA:any, assignmentB:any) {
                                                const dateA = +new Date(assignmentA['dueDate']);
                                                const dateB = +new Date(assignmentB['dueDate']);
                                                return dateA - dateB;
                                            }).map(item => { 
                                                return item.score 
                                            });

                                            return (
                                                <Grid item xs={3} style={this.standardColStyle} key={standardIndex}>
                                                    <p style={{marginBottom: '.5rem'}}>{standard.standardName}</p>
                                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%'}}>
                                                        <p style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: '50%', marginBottom: 0 }}>
                                                            <span>E</span>
                                                            <span>M</span>
                                                            <span>A</span>
                                                            <span>D</span>
                                                        </p>
                                                        <StandardLineGraph 
                                                            proficiencyScore={standard.proficiency.proficiencyScore}
                                                            proficiencyType={ProficiencyType.Word}
                                                            proficiencyArray={proficiencyArray}
                                                            graphLevels={graphLevelsArray}
                                                            viewSkillProficienies={ () => { }}
                                                            forPrint
                                                        />
                                                    </div>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })} 
                </div>               
            </div>
        );
    }

    private getTeacherName = (): string => {
        let teacherName = "";
        if (this.props.gradebookState.selectedTeacher) {
            teacherName = `${this.props.gradebookState.selectedTeacher.lastName}, ${this.props.gradebookState.selectedTeacher.firstName}`;
        }
        return teacherName;
    }

    private getStudentName = (student: TeacherStudent): string => {
        return `${student.lastName}, ${student.firstName}`;
    }

    private  getHomeworkBoolean = (gradebookEntry: GradebookEntry) : boolean[] =>{
        let assignmentList: Assignment[] = [];
        for(let i = 0; i < gradebookEntry.assessment.standards.length; i++){
            assignmentList = assignmentList.concat(gradebookEntry.assessment.standards[i].assignments.filter(x=>x.isHomework && x.isNotAssigned === false));
        }
        return assignmentList.sort(y=>y.dueDate.valueOf()).map(z=>z.isMissing);
    }

}