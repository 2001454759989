import React from 'react';
import './Shared.css';
import { GradebookEntry } from '../../models/GradebookEntry';
import PanelTitleBar, { PanelTitleTypes } from '../layout/PanelTitleBar';
import { MttButton } from '../inputs-elements/MttButton';
import { MttStatus } from '../../models/enums/Mtt';
import { globalSelector} from "store/global/globalReducer";
import { useSelector } from 'react-redux';

interface IMttColumn {
    grades: GradebookEntry[];
    onMttButtonClick: (selectedStudentGradeBook: GradebookEntry) => void;
    disableMttButton:boolean;
}

export const MttColumn = ( {grades, onMttButtonClick, disableMttButton}:IMttColumn) => {
    const { isFinalWeekMode, termIsEditable} = useSelector(globalSelector);

    return (
      <>
        <PanelTitleBar  mainTitle='MTT' toolBarType={PanelTitleTypes.Secondary} textAlign='center'/>
        <table className='table table-striped gradebookTable' aria-labelledby="tabelLabel">
            <thead className="gradebookTableHeaderRow">
                <tr>
                    <th> </th>
                </tr>
            </thead>
            <tbody>
                {grades.map((grade: GradebookEntry)=>{
                    return <tr className={`studentRow`} key={grade.student.personId} >
                        <td>
                            <div className="gradebookTableRow" style={{textAlign: 'center',cursor: 'default'}}>

                                {!grade.student.isDropped && (
                                    <MttButton
                                        buttonTitle={grade.student.personId.toLocaleString()}
                                        menuPrimaryFunction={() => onMttButtonClick(grade)}
                                        mttStatus={
                                            isFinalWeekMode && termIsEditable ? MttStatus.ExitedMtt :
                                            grade.mandatoryTargetedTutoring.currentStudentStatus
                                        }
                                        isDisabled={disableMttButton}
                                    />
                                )}

                                {/* New Status for Dropped && Exited */}
                                {(grade.student.isDropped && grade.mandatoryTargetedTutoring.currentStudentStatus === MttStatus.ExitedMtt)  && (
                                    <MttButton
                                        buttonTitle={grade.student.personId.toLocaleString()}
                                        menuPrimaryFunction={() => onMttButtonClick(grade)}
                                        mttStatus={MttStatus.ExitedDroppedMtt}
                                        isDisabled={disableMttButton}
                                    />
                                )}


                            </div>
                        </td>
                    </tr>
                    }
                )}
            </tbody>
        </table>
      </>
    );
  };