import { Standard } from "./Standard";

export interface AssessmentJson {
    studentPersonID: number;
    sectionID: number;
    projectedGrade: string;
    finalGrade: string;
    isVerified: boolean;
    weeklyGrowth: string;
    standards: Standard[];
    isFinal: boolean;
}


export class Assessment {

    public static fromJson = (gradebookEntry: AssessmentJson) => {
        if (gradebookEntry) {
            return new Assessment(
                gradebookEntry.studentPersonID,
                gradebookEntry.sectionID,
                gradebookEntry.projectedGrade,
                gradebookEntry.finalGrade,
                gradebookEntry.isVerified,
                gradebookEntry.weeklyGrowth,
                gradebookEntry.standards, 
                gradebookEntry.isFinal,
            );
        }
        return new Assessment();
    }

    constructor(
        public studentPersonID: number = -1,
        public sectionID: number = -1,
        public projectedGrade: string = '',
        public finalGrade: string = '',
        public isVerified: boolean = false,
        public weeklyGrowth: string = '',
        public standards: Standard[] = [],
        public isFinal: boolean = false,
    ) {}
}