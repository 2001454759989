
import { http } from '../utils/helpers/apiHelpers'
import { IStudentGradeBook, IStudentSkillProficiency } from '../models/GradebookEntry'

/**  Request Update Growth Score for multiple or one students and expect reponse for eligibilty*/
export const updateStudentsGrowth = async (body:IStudentGradeBook[]): Promise<any> => {
    return await http.post<any>(`mtt/grade`, body);   
};

/**  Request to get Available growth options (assignable) based on current week*/
export const getGrowthScoringOptions = async (sectionId: number, weekTaskId:number ): Promise<any> => {
    return await http.get<any>(`mtt/gradeOptions/${sectionId}/${weekTaskId}`);
};

/**  Request to get Direct Grade Options during Final Week*/
export const getDirectGradeOptions = async (sectionId: number): Promise<any> => {
    return await http.get<any>(`finalweek/DirectGradeOptions/${sectionId}`);
};

/**  Verify / UnVerify Final Grade And Or add Final Grade Comments */
export const postStudentsFinalGradeStatus = async (body:IStudentGradeBook[], teacherId: number, viewingTermId: number, statusChanged: boolean = false): Promise<any> => {
    return await http.post<any>(`finalweek/finalgradestatus?teacherId=${teacherId}&viewingTermId=${viewingTermId}&verificationStatusChanged=${statusChanged}`, body);   
};


/**  Verify / UnVerify Final Grade, Assign Direct Grade And Or add Final Grade Comments */
export const postDGStudentsFinalGradeStatus = async (body:IStudentGradeBook[], teacherId: number, viewingTermId: number, statusChanged: boolean = false): Promise<any> => {
    return await http.post<any>(`finalweek/directfinalgradestatus?teacherId=${teacherId}&viewingTermId=${viewingTermId}&verificationStatusChanged=${statusChanged}`, body);   
};

/**  Override Skill Proficiency for a Student during Final Week*/
export const postSkillProficiency = async (body:IStudentSkillProficiency, viewingTermId: number): Promise<any> => {
    return await http.post<any>(`finalweek/skilloverride?viewingTermId=${viewingTermId}`, body);   
};

/** Get Latest Assesments */ 
export const getStudentAssesments = async (sectionId: number, studentIds: number[]): Promise<any> => {
    const studentIdsQuery = studentIds.map((studentId) => {
        return `&studentIds=${studentId.toLocaleString().replace(/,/g, '')}`;
    });
    return await http.get<any>(`finalweek/AssessmentByStudent?sectionId=${sectionId}${studentIdsQuery.join("")}&isFinalWeekMode=true`);   
};