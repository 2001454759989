import React, { useEffect, useState } from 'react';
import { AxiosResponse } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { GradebookEntry } from 'models/GradebookEntry';
import { IStudentWeeklyGrowthScores } from 'models/Student';
import { TeacherStudent } from 'models/TeacherStudent';
import { HeaderSelectInput } from '../inputs-elements/CustomInputs';
import PanelTitleBar, { PanelTitleTypes } from '../layout/PanelTitleBar';
import EditWeeklyGrowthMenu, { MenuLayout } from '../navigation/EditWeeklyGrowthMenu';
import { CommentDialog } from '../layout/CommentDialog';
import { updateStudentsGrowth, getDirectGradeOptions } from 'api/gradebookApi';
import { IWeekDate } from 'models/WeekDate';
import { WeekType } from 'models/WeeklyGrowth';
import { Assessment } from 'models/Assessment';
import './Shared.css';
import { IDirectGradeOption } from 'models/DirectGradeOption';
import { IMenuListItems, IWeekOptions } from 'models/Menus';
import { IActiveStudent, IStudentWeeklyGrowth } from 'models/Student';
import { NotificationsType, updateNotificationsAction } from "../../store/notifications/notificationsReducer";
import { updateWeeklyGrowthAction, updateMttEligibiltyAction, gradebooksSelector, initializeEligibiltyAndGradeAction, viewPreviousWGScoresAction,updateFinalGradeStatusAction, initializeFinalVerificationAction, updateStudentAssessmentAction, initializeStudentAssessmentAction} from "../../store/student/gradebookReducer";
import { globalSelector, updateCurrentWeekDateAction, updateViewingWeekTypeAction } from "../../store/global/globalReducer";
import { hasWeeklyScoreAlready, getWeeklyGrowthOptions, getWeeklyGrowthScores, shortenWeeklyGrowthValue, isFinalsWeek, convertDgToMenuList } from "../../utils/helpers/generalHelpers";
import HoverModal, { HoverModalTypes, HoverModalContent } from '../layout/HoverModal';
import { postStudentsFinalGradeStatus, postDGStudentsFinalGradeStatus, getStudentAssesments } from 'api/gradebookApi';
import { VerificationSwitch } from 'components/inputs-elements/VerificationSwtich';
import { DirectGradeVerificationSwitch } from 'components/inputs-elements/DirectGradeVerificationSwitch';

import { IStudentGradeBook } from 'models/GradebookEntry';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styled from "styled-components";

export interface IWeeklyGrowthColumnProps {
  grades: GradebookEntry[];
  onWeeklySelect: (selectedGradeBook: GradebookEntry) => void;
  onCurrentWeekChange: (taskId: number) => void;
  availableOptions: IMenuListItems[];
  disableWeeklyGrowthButton: boolean;
  termIsEditable: boolean;
  isFinal: boolean;
  selectedTerm: number;
}

export const WeeklyGrowthColumn = (props: IWeeklyGrowthColumnProps) => {
  //#region Variables:
  const [commentDialogIsOpen, setCommentDialogIsOpen] = useState(false);
  const [activeStudent, setActiveStudent] = useState<IActiveStudent>({ studentName: '', studentId: -1, directGradeEligible:false, studentProjectedGrade: '', studentFinalGrade: '', studentFinalGradeStatus: undefined, studentMttStatus: '', sectionId: -1, scoreText: '', scoreIntials: '' });
  const [assignWOutComments, setAssignWOutComments] = useState(false);
  const [studentCurrentWeekScore, setStudentCurrentWeekScore] = useState({ currentScoreComment: '', currentScore: '', hasScoreAlready: false });
  const [weeklyGrowthOptions, setWeeklyGrowthOptions] = useState<Array<IWeekOptions>>([]);
  const [activeWeeklyGrowthOption, setActiveWeeklyGrowthOption] = useState<IWeekOptions>({ code: '', task: '', taskId: -1, hideProjectedGrade: true });
  const [studentsWeeklyGrowth, setStudentsWeeklyGrowth] = useState<Array<IStudentWeeklyGrowth>>([]);
  const [directGradeOptions, setDirectGradeOptions] = useState<Array<IMenuListItems>>([]);

  const dispatch = useDispatch();
  const { allGradebooks, activeSectionId, activeTeacherId } = useSelector(gradebooksSelector);
  const { currentWeekTaskId, currentTermId, isFinalWeekMode, viewingWeekType } = useSelector(globalSelector);

  const TableBody = styled.tbody<{ weekType: WeekType }>`
    background: ${({ weekType, theme: { colors } }) => weekType === WeekType.CurrentWeek ? '' : weekType === WeekType.FinalWeek ? colors.gold.lightAlert : weekType === WeekType.PastWeek ? colors.greens.xlight : ''};
    tr{ background-color:${({ weekType, theme: { colors } }) => weekType === WeekType.CurrentWeek ? '' : weekType === WeekType.FinalWeek ? colors.gold.lightAlert + '!important' : weekType === WeekType.PastWeek ? colors.greens.xlight + '!important' : ''}; 
    td{ border-top:1px solid ${({ weekType, theme: { colors } }) => weekType === WeekType.FinalWeek ? colors.gold.dark + '!important' : '#dee2e6'};
  `;
  //#endregion Variables:

  //#region Hooks:
  useEffect(() => {
    // If Assigning a new score with out comments we call the api directly
    if (assignWOutComments && (viewingWeekType !== WeekType.FinalWeek)) {
      updateWeeklyGrowthScore();
    }

    if (assignWOutComments && isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek)) {
      activeStudent.directGradeEligible ? updateDGStudentsFinalGradeStatus(undefined, true) : updateStudentsFinalGradeStatus(undefined, true);
    }

  }, [assignWOutComments,activeStudent.directGradeEligible, viewingWeekType, isFinalWeekMode]);


  useEffect(() => {

    if (isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) && activeSectionId !== -1) {
  
      fetchDirectGradeOptions(activeSectionId);
    }
 
  }, [viewingWeekType, isFinalWeekMode, activeSectionId]);




  useEffect(() => {
    if (currentWeekTaskId && isFinalsWeek(currentWeekTaskId)) {
      dispatch(updateViewingWeekTypeAction(WeekType.FinalWeek));
    }


    if (currentWeekTaskId && !isFinalsWeek(currentWeekTaskId) && isFinalWeekMode && (viewingWeekType !== WeekType.PastWeek)) {
      dispatch(updateViewingWeekTypeAction(WeekType.FinalWeek));
    }
 
  }, [currentWeekTaskId, viewingWeekType]);
 
  useEffect(() => {
    if (props.grades.length > 0) {
      const studentsWg: Array<IStudentWeeklyGrowth> = [];
      props.grades.forEach(gradebookItem => {
        studentsWg.push({
          studentId: gradebookItem.student.personId,
          weeklyGrowthArray: gradebookItem.weeklyGrowth
        })
      });
      const weeklyGrowthOptions: IWeekOptions[] = getWeeklyGrowthOptions(studentsWg);

      // We only want to update the options and growth scores once on course gradebook load            
      if (weeklyGrowthOptions.length > 0 && activeWeeklyGrowthOption.code === '') {
        setWeeklyGrowthOptions(weeklyGrowthOptions);
        setStudentsWeeklyGrowth(studentsWg);
        populateWeeklyGrowthScores(weeklyGrowthOptions[0].code, weeklyGrowthOptions[0], studentsWg);
      }

    }
  }, [props.grades, activeWeeklyGrowthOption]);


  useEffect(() => {
    const newActiveWeek:IWeekDate = {...activeWeeklyGrowthOption};
    newActiveWeek.termId = props.selectedTerm;

    dispatch(updateCurrentWeekDateAction(newActiveWeek));
  }, [activeWeeklyGrowthOption, weeklyGrowthOptions]);

  //#endregion Hooks:

  //#region Async Functions:
  async function updateWeeklyGrowthScore(newCommentText?: string) {
    dispatch(initializeEligibiltyAndGradeAction([{ studentId: activeStudent.studentId }]));
    const response = await updateStudentsGrowth([{
      studentPersonId: activeStudent.studentId,
      sectionId: activeStudent.sectionId,
      weeklyGrowthScore: activeStudent.scoreIntials,
      projectedGrade: activeStudent.studentProjectedGrade,
      currentMTTStatus: activeStudent.studentMttStatus,
      comment: newCommentText ? newCommentText : '',
      taskId: currentWeekTaskId,
      termId: currentTermId
    }]).catch(error => {
      dispatch(updateNotificationsAction({
        notificationStatus: NotificationsType.Error,
        notificationMessage: `Error: ${error.statusText} | Updating Student Growth / Eligibilty`
      }));
      setCommentDialogIsOpen(false);
    });
    if (response && response.data) {
      const correctStudent = response.data.find(i => i.studentPersonId === activeStudent.studentId);
      dispatch(updateWeeklyGrowthAction([{
        studentPersonId: activeStudent.studentId,
        sectionId: activeStudent.sectionId,
        weeklyGrowthScore: activeStudent.scoreIntials,
        scoreText: activeStudent.scoreText,
        projectedGrade: correctStudent ? correctStudent['projectedGrade'] : '',
        comment: newCommentText ? newCommentText : '',
        currentWeek: correctStudent ? correctStudent['currentWeek'] : '',
        taskId: currentWeekTaskId,
        sequence: correctStudent ? correctStudent['seq'] : 0
      }]));
      setCommentDialogIsOpen(false);
      setAssignWOutComments(false);
      // Based on the response we also update local store Eligibilty / Notifications
      dispatch(updateMttEligibiltyAction([{
        studentId: activeStudent.studentId,
        mttStatus: correctStudent ? correctStudent['eligiblityStatus'] : 0
      }]));
      dispatch(updateNotificationsAction({ notificationStatus: NotificationsType.Success, notificationMessage: 'Growth / Eligibilty Updated' }));
    }
  } 

  // Used during the final week of a term to either verify a final grade or add a final grade comment.
  async function updateStudentsFinalGradeStatus(newCommentText?: string, updateFinalGradeStatus: boolean | undefined = undefined) {

    const finalGradeStatusChanged: boolean = (updateFinalGradeStatus !== undefined) && activeStudent.studentFinalGradeStatus !== undefined ? true : false;
    let bodyRequest: IStudentGradeBook = { studentPersonId: -1, sectionId: -1 };
    let successMessage: string = ``;

    dispatch(initializeFinalVerificationAction([{ studentId: activeStudent.studentId }]));

    // Update Status and Add Comment
    if (finalGradeStatusChanged && newCommentText) {
      bodyRequest = {
        studentPersonId: activeStudent.studentId,
        sectionId: activeStudent.sectionId,
        directGradeEligible:activeStudent.directGradeEligible,
        // if the student is direct grade eligible then we want to assign the new one, if not then we keep the final grade 
        finalGrade: activeStudent.directGradeEligible ? activeStudent.scoreIntials : activeStudent.studentFinalGrade,
        isVerified: activeStudent.studentFinalGradeStatus ? false : true,
        comment: newCommentText,
      };
      successMessage = `Successfully ${activeStudent.studentFinalGradeStatus ? 'unverified' : 'verified'} final grade & comment`;
    }

    // Only add comment
    if (!finalGradeStatusChanged && newCommentText) {
      bodyRequest = { 
        studentPersonId: activeStudent.studentId, 
        sectionId: activeStudent.sectionId,
        directGradeEligible:activeStudent.directGradeEligible, 
        finalGrade: activeStudent.directGradeEligible ? activeStudent.scoreIntials : activeStudent.studentFinalGrade,
        comment: newCommentText 
      };
      successMessage = `Successfully updated comment`;
    }
    // Only update status
    if (finalGradeStatusChanged && !newCommentText) {
      bodyRequest = {
        studentPersonId: activeStudent.studentId,
        sectionId: activeStudent.sectionId,
        directGradeEligible:activeStudent.directGradeEligible,
        // if the student is direct grade eligible then we want to assign the new one, if not then we keep the final grade 
        finalGrade: activeStudent.directGradeEligible ? activeStudent.scoreIntials : activeStudent.studentFinalGrade,
        isVerified: activeStudent.studentFinalGradeStatus ? false : true,
      };
      successMessage = `Successfully ${activeStudent.studentFinalGradeStatus ? 'unverified' : 'verified'} final grade`;
    }

 
    const response: AxiosResponse<IStudentGradeBook[]> = await postStudentsFinalGradeStatus([bodyRequest], activeTeacherId, currentTermId, finalGradeStatusChanged).catch(error => {
      errorFinalGradeStatus(activeStudent.studentId, activeStudent.sectionId, activeStudent.studentFinalGrade, undefined);
      // TODO : Add App Insights error
    });

    if (response && response.data) {
      const correctStudent = response.data.find(i => i.studentPersonId === activeStudent.studentId);


      if (correctStudent) {
        setCommentDialogIsOpen(false);
        setAssignWOutComments(false);

        // If a final grade status is UNVERIFIED, then we need to get the latest assessment that may have been updated from Infinite Campus,
        if(correctStudent.isVerified === false && finalGradeStatusChanged){
          fetchUpdatedStudentAssesment( activeStudent.sectionId ,activeStudent.studentId);

        }else{

          dispatch(updateFinalGradeStatusAction([{
            studentPersonId: activeStudent.studentId,
            sectionId: activeStudent.sectionId,
            isVerified: updateFinalGradeStatus == undefined ? activeStudent.studentFinalGradeStatus : activeStudent.studentFinalGradeStatus ? false : true,
            finalGrade: activeStudent.studentFinalGrade,
            comment: correctStudent.comment ? correctStudent.comment : undefined,
            sequence: correctStudent ? correctStudent['seq'] : 0,
            taskId: currentWeekTaskId,
          }]));
          dispatch(updateNotificationsAction({
            notificationStatus: NotificationsType.Success,
            notificationMessage: successMessage
          }));

        }
 


      }
    } else {
      // reset to pre error state
      errorFinalGradeStatus(activeStudent.studentId, activeStudent.sectionId, activeStudent.studentFinalGrade, undefined);
    }
  }

  async function updateDGStudentsFinalGradeStatus(newCommentText?: string, updateFinalGradeStatus: boolean | undefined = undefined) {
    const newDirectGradeAssigned: boolean = (activeStudent.directGradeEligible && activeStudent.scoreIntials !== '') ? true : false;
    const verificationStatusChanged: boolean = (updateFinalGradeStatus !== undefined) && (activeStudent.studentFinalGradeStatus !== undefined) && !newDirectGradeAssigned ? true : false;

    let bodyRequest: IStudentGradeBook = { studentPersonId: -1, sectionId: -1 };
    let successMessage: string = ``;
 
    dispatch(initializeFinalVerificationAction([{ studentId: activeStudent.studentId }]));
  
    // Assign Direct Grade only
    if (!verificationStatusChanged && !newCommentText && newDirectGradeAssigned) {
      bodyRequest = {
        studentPersonId: activeStudent.studentId,
        sectionId: activeStudent.sectionId,
        directGradeEligible:activeStudent.directGradeEligible,
        // scoreIntials is the new final grade we want to assign 
        finalGrade: activeStudent.scoreIntials,
        isVerified: verificationStatusChanged ? (activeStudent.studentFinalGradeStatus ? false : true) : activeStudent.studentFinalGradeStatus
      };
      successMessage = `Successfully assigned a new Final Direct Grade`;
    }

    // Assign a Direct Grade and Comment via dg menu
    if (newCommentText && newDirectGradeAssigned && !verificationStatusChanged) {
      bodyRequest = { 
        studentPersonId: activeStudent.studentId, 
        sectionId: activeStudent.sectionId,
        directGradeEligible:activeStudent.directGradeEligible, 
        finalGrade: activeStudent.scoreIntials,
        isVerified: activeStudent.studentFinalGradeStatus,
        comment: newCommentText 
      };
      successMessage = `Successfully assigned a new final grade and comment`;
    }

    // Assign a comment only / comment via switch
    if (newCommentText && !verificationStatusChanged && !newDirectGradeAssigned) {
      bodyRequest = { 
        studentPersonId: activeStudent.studentId, 
        sectionId: activeStudent.sectionId,
        directGradeEligible:activeStudent.directGradeEligible, 
        finalGrade: '',
        isVerified: activeStudent.studentFinalGradeStatus,
        comment: newCommentText 
      };
      successMessage = `Successfully updated comment`;
    }

    // Update Verification Status only
    if (verificationStatusChanged && !newCommentText && !newDirectGradeAssigned) {
      bodyRequest = {
        studentPersonId: activeStudent.studentId,
        sectionId: activeStudent.sectionId,
        directGradeEligible:activeStudent.directGradeEligible,
        // if the student is direct grade eligible then we want to assign the new one, if not then we keep the final grade 
        finalGrade: activeStudent.scoreIntials !== '' ? activeStudent.scoreIntials : activeStudent.studentFinalGrade,
        isVerified: activeStudent.studentFinalGradeStatus ? false : true,
      };
      successMessage = `Successfully ${activeStudent.studentFinalGradeStatus ? 'unverified' : 'verified'} final grade`;
    }

    const response: AxiosResponse<IStudentGradeBook[]> = await postDGStudentsFinalGradeStatus([bodyRequest], activeTeacherId, currentTermId, verificationStatusChanged).catch(error => {
      errorFinalGradeStatus(activeStudent.studentId, activeStudent.sectionId, activeStudent.studentFinalGrade, undefined);
      // TODO : Add App Insights error
    });

    if (response && response.data) {
      const correctStudent = response.data.find(i => i.studentPersonId === activeStudent.studentId);

      if (correctStudent) {
        setCommentDialogIsOpen(false);
        setAssignWOutComments(false);

        if(correctStudent.isVerified === false && verificationStatusChanged){
          fetchUpdatedStudentAssesment( activeStudent.sectionId ,activeStudent.studentId);

        }else{
          dispatch(updateFinalGradeStatusAction([{
            studentPersonId: activeStudent.studentId,
            sectionId: activeStudent.sectionId,
            isVerified: correctStudent.isVerified,
            finalGrade: (newDirectGradeAssigned && activeStudent.directGradeEligible) ? activeStudent.scoreIntials : activeStudent.studentFinalGrade,
            comment: correctStudent.comment ? correctStudent.comment : undefined,
            sequence: correctStudent ? correctStudent['seq'] : 0,
            taskId: currentWeekTaskId,
          }]));
          dispatch(updateNotificationsAction({
            notificationStatus: NotificationsType.Success,
            notificationMessage: successMessage
          }));

        }


        



 
      }
    } else {
      // reset to pre error state
      errorFinalGradeStatus(activeStudent.studentId, activeStudent.sectionId, activeStudent.studentFinalGrade, undefined);
    }
  }


  async function fetchUpdatedStudentAssesment(sectionId: number, studentId: number) {

    dispatch(initializeStudentAssessmentAction([{ studentId: activeStudent.studentId }]));

  
    const response: AxiosResponse<Assessment[]> = await getStudentAssesments(sectionId, [studentId]).catch(error => { 
          // TODO : Add App Insights error
          dispatch(updateNotificationsAction({
            notificationStatus: NotificationsType.Error,
            notificationMessage: `Error: Getting Student(s) Assessment`
          }));
    });

 
    if (response && response.data) {
      const correctStudent = response.data.find(i => i.studentPersonID === activeStudent.studentId);

      if (correctStudent) {
        dispatch(updateStudentAssessmentAction([{
          studentPersonID: activeStudent.studentId,
          sectionID: activeStudent.sectionId,
          isVerified:  correctStudent.isVerified,
          finalGrade: correctStudent.finalGrade,
          projectedGrade: correctStudent.projectedGrade,
          standards: correctStudent.standards,
          weeklyGrowth: '',
          // TODO REVIEW
          isFinal:false
        }]));
        dispatch(updateNotificationsAction({
          notificationStatus: NotificationsType.Success,
          notificationMessage: `Successfully unverified the final grade & retrieved latest Skills`
        }));
      }
    }else{
      dispatch(updateNotificationsAction({
        notificationStatus: NotificationsType.Error,
        notificationMessage: `Error: Getting Student(s) Assessment`
      }));
    }

  }


  async function fetchDirectGradeOptions(currentSectionId: number) {

    const response = await getDirectGradeOptions(currentSectionId).catch(error => {

    });
 
    if (response && response.data) {
      const responseDirectGradeOptions: IDirectGradeOption[] = response.data.map(option => ({
        score: option.score,
        seq: option.seq
      }));
      setDirectGradeOptions(convertDgToMenuList(responseDirectGradeOptions));
    }
  }


  //#endregion Async Functions:

  //#region Functions:
  const updateStudentAndScore = (student: TeacherStudent, projectedGrade: string, currentMTTStatus: string = '', newScoreText: string = '', newScoreIntials: string = '', openWGCommentsDialog: boolean, finalGrade: string = '', finalGradeCurrenlyVerified?: boolean) => {

    setActiveStudent({
      studentName: student.lastName + ', ' + student.firstName,
      studentId: student.personId,
      sectionId: student.sectionId,
      directGradeEligible:student.directGradeEligible,
      scoreText: newScoreText,
      scoreIntials: newScoreIntials,
      studentProjectedGrade: projectedGrade,
      studentFinalGrade: student.directGradeEligible && newScoreIntials && !finalGrade ? newScoreIntials : finalGrade,
      studentFinalGradeStatus: finalGradeCurrenlyVerified,
      studentMttStatus: currentMTTStatus
    });

    const activeStudentGradebook: GradebookEntry = allGradebooks.find((gradeBook: GradebookEntry) => gradeBook.student.personId === student.personId);
    if (activeStudentGradebook) {
      // Auto fill comments and score if there is an exsiting one already for the week.
      const { hasWeeklyScore, score, comment } = hasWeeklyScoreAlready(activeStudentGradebook.weeklyGrowth, currentWeekTaskId);
      if (hasWeeklyScore)
        setStudentCurrentWeekScore({ currentScoreComment: comment, currentScore: score, hasScoreAlready: true });
    }

    openWGCommentsDialog ? setCommentDialogIsOpen(true) : setAssignWOutComments(true);
  };

  const populateWeeklyGrowthScores = (weekName: string, activeWGOption: IWeekOptions, studentsWg?: IStudentWeeklyGrowth[]): void => {
    const newActiveWGOption = activeWGOption ? activeWGOption : weeklyGrowthOptions.find(option => option.task === weekName);
 
    
    const newStudentsWg = studentsWg ? studentsWg : studentsWeeklyGrowth;
    if (newActiveWGOption) {
      setActiveWeeklyGrowthOption(newActiveWGOption);
      const weeklyGrowthStudentScores: IStudentWeeklyGrowthScores[] = getWeeklyGrowthScores(newStudentsWg, newActiveWGOption.task);
      dispatch(viewPreviousWGScoresAction(weeklyGrowthStudentScores));
      dispatch(updateNotificationsAction({
        notificationStatus: NotificationsType.Success,
        notificationMessage: `Viewing ${newActiveWGOption.task} Scores`
      }));
      const updatedWeek: IWeekDate = {
        taskId: newActiveWGOption.taskId,
        task: newActiveWGOption.task,
        termId: props.selectedTerm,
        hideProjectedGrade: newActiveWGOption.hideProjectedGrade
      }
      dispatch(updateCurrentWeekDateAction(updatedWeek));
      props.onCurrentWeekChange(newActiveWGOption.taskId);
    }
  }

  const viewPastWeeklyGrades = (weekName: string): void => {

    const newActiveWGOption: IWeekOptions | undefined = weeklyGrowthOptions.find(option => option.task === weekName);
    if (newActiveWGOption)
      populateWeeklyGrowthScores(weekName, newActiveWGOption);
    if (newActiveWGOption !== weeklyGrowthOptions[0]) {
      dispatch(updateViewingWeekTypeAction(WeekType.PastWeek));
    } else if (newActiveWGOption === weeklyGrowthOptions[0] && isFinalsWeek(newActiveWGOption.taskId)) {
      dispatch(updateViewingWeekTypeAction(WeekType.FinalWeek));
    } else {
      dispatch(updateViewingWeekTypeAction(WeekType.CurrentWeek));
    }
  }

  const renderOptionValue = (valueText: string): string => {
    if (props.isFinal && (weeklyGrowthOptions['0'].task === valueText || weeklyGrowthOptions['0'].code === valueText))
      return 'Final';
    return valueText;
  }

  const errorFinalGradeStatus = (studentId: number, sectionId: number, finalGrade: string | undefined, isVerified: boolean | undefined): void => {
    dispatch(updateFinalGradeStatusAction([{
      studentPersonId: studentId,
      sectionId: sectionId,
      isVerified: isVerified,
      finalGrade: finalGrade
    }]));
    dispatch(updateNotificationsAction({
      notificationStatus: NotificationsType.Error,
      notificationMessage: `Error: Failed to update the Final Grade Status`
    }));
  }

  //#endregion Functions:


  //#region Render:
  return (
    <>
      <PanelTitleBar
        toolBarType={PanelTitleTypes.Seventh}
        textAlign="center"
      >
        <Select
          id="demo-simple-select"
          onChange={(event: SelectChangeEvent) => viewPastWeeklyGrades(event.target.value)}
          label="Growth"
          value={activeWeeklyGrowthOption.task}
          renderValue={(value) => `${renderOptionValue(shortenWeeklyGrowthValue(value))}`}
          input={<HeaderSelectInput />}
          disabled={!props.termIsEditable}
        >
          {weeklyGrowthOptions.map((item: IWeekOptions, index) => {
            return (
              <MenuItem key={index} value={item.task}>{renderOptionValue(item.task)}</MenuItem>
            );
          })}
        </Select>
      </PanelTitleBar>
      <table className='table table-striped gradebookTable' aria-labelledby="tabelLabel">
        <thead className="gradebookTableHeaderRow">
          <tr>
            <th style={{ fontWeight: 'bold', fontSize: '13px', textAlign: 'center' }}>
              {viewingWeekType === WeekType.PastWeek && (
                <HoverModal
                  title={shortenWeeklyGrowthValue(activeWeeklyGrowthOption ? activeWeeklyGrowthOption.task : '')}
                  type={HoverModalTypes.Third}
                  content={HoverModalContent.pastWeek}
                />
              )}

              {viewingWeekType === WeekType.FinalWeek && (
                <HoverModal
                  title={shortenWeeklyGrowthValue(activeWeeklyGrowthOption ? activeWeeklyGrowthOption.task : '')}
                  type={HoverModalTypes.Sixth}
                  content={HoverModalContent.finalWeek}
                />
              )}
            </th>
          </tr>
        </thead>
        <TableBody weekType={viewingWeekType} >
          {props.grades.map((grade: GradebookEntry, index: number) => {
            return <tr className={`studentRow`} key={grade.student.personId} >
              <td>
                <div className="gradebookTableRow" style={{ textAlign: 'center', cursor: 'default' }}>

                  {/* Viewing regular non final week */}
                  {viewingWeekType !== WeekType.FinalWeek && (
                    !grade.student.isDropped && (
                      <EditWeeklyGrowthMenu
                        buttonText={grade.assessment.weeklyGrowth}
                        secondaryButtonText={`View Growth`}
                        menuTitle={`Assign Growth`}
                        menuId={grade.student.personId.toLocaleString()}
                        menuItems={props.availableOptions}
                        menuLayout={MenuLayout.ButtonModalLayout}
                        updateGradeFunction={(updatedWGScoreText: string, updatedWGScoreInitials: string, withComments: boolean) =>
                          updateStudentAndScore(grade.student, grade.assessment.projectedGrade, grade.mandatoryTargetedTutoring.currentStudentStatus, updatedWGScoreText, updatedWGScoreInitials, withComments)
                        }
                        viewWeeklyGrowthFunction={() => props.onWeeklySelect(grade)}
                        disableButton={props.disableWeeklyGrowthButton}
                      />
                    )
                  )}

                  {/* Is either a real Final Week, or App is set to Final week mode */}
                  {viewingWeekType === WeekType.FinalWeek && (

                    // Suggested Final Grade Verification Switch
                    !grade.student.isDropped && !grade.student.directGradeEligible ? (
                      <VerificationSwitch
                        buttonId={grade.student.personId + `-` + index}
                        labelText={grade.assessment.finalGrade}
                        isVerified={grade.assessment.isVerified}
                        switchPrimaryFunction={(withComments: boolean = false) =>
                          updateStudentAndScore(
                            grade.student,
                            grade.assessment.projectedGrade,
                            grade.mandatoryTargetedTutoring.currentStudentStatus,
                            grade.assessment.finalGrade,
                            grade.assessment.finalGrade,
                            withComments,
                            grade.assessment.finalGrade,
                            grade.assessment.isVerified
                          )
                        }
                        viewWeeklyGrowthFunction={() => props.onWeeklySelect(grade)}
                      />
                    ) : (

                      !grade.student.isDropped && grade.student.directGradeEligible && (
                        
                        <DirectGradeVerificationSwitch
                          buttonId={grade.student.personId + `-` + index}
                          labelText={
                            grade.assessment.finalGrade !== '' && grade.assessment.finalGrade !== null ? 
                              grade.assessment.finalGrade : 
                              (grade.assessment.projectedGrade !== "" && grade.assessment.projectedGrade !== null) ? grade.assessment.projectedGrade : 
                              '-' 
                          }
                          isVerified={grade.assessment.isVerified}
                          isSwitchDisabled={grade.assessment.finalGrade !== '' && grade.assessment.finalGrade !== null ? false : true}
                          updateDirectGradeFunction={(directGrade: string, withComments: boolean = false) =>
                            updateStudentAndScore(
                              grade.student,
                              grade.assessment.projectedGrade,
                              grade.mandatoryTargetedTutoring.currentStudentStatus,
                              directGrade,
                              directGrade,
                              withComments,
                              grade.assessment.finalGrade,
                              grade.assessment.isVerified

                            )
                          }
                          updateVerificationStatus={(withComments: boolean = false) =>
                            updateStudentAndScore(
                              grade.student,
                              grade.assessment.projectedGrade,
                              grade.mandatoryTargetedTutoring.currentStudentStatus,
                              ``,
                              ``,
                              withComments,
                              grade.assessment.finalGrade,
                              grade.assessment.isVerified
                            )
                          }
                          menuTitle='Assign a Direct Grade'
                          menuItems={directGradeOptions}
                          viewWeeklyGrowthFunction={() => props.onWeeklySelect(grade)}
                        />
                      )

                    )

                    // Direct Final Grade Verification Switch





                  )}

                </div>
              </td>
            </tr>
          }
          )}
        </TableBody>
      </table>

      {/*  `This student has been assigned a weekly growth score this week`*/}
      <CommentDialog
        title={
          isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ?
          activeStudent.directGradeEligible ?  
            activeStudent.scoreIntials == `` ? `Add a Final Comment for  ${activeStudent.studentName}` : `Assign a Direct Grade \\ Final Comment for  ${activeStudent.studentName}` : 
            `Add a Final Comment for  ${activeStudent.studentName}`
          :
          `Assign ${activeStudent.studentName} a (${activeStudent.scoreText}) with comments`
        }
        descriptionText={isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ?
          activeStudent.directGradeEligible ?  
          activeStudent.scoreIntials == `` ? `` : `You are assigning ${activeStudent.studentName} a final grade of (${activeStudent.scoreIntials})` : 
          `You can also ${activeStudent.studentFinalGradeStatus ? `unverify` : `verify`} the final grade of (${activeStudent.studentFinalGrade}) by toggling the switch`
          : `` 
        }
        helperText={isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ? '' : studentCurrentWeekScore.currentScore ?
          `This student has been assigned a (${studentCurrentWeekScore.currentScore}) score this week already` : ''
        }
        commentPlaceholder={`${isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ? `Enter a final comment or` : `Enter a weekly comment or`}  choose from the picklist`}
        submitBtnText='Save'
        cancelBtnText='Cancel'
        isOpen={commentDialogIsOpen}
        showCommentOptions
        commentValue={
          ( studentCurrentWeekScore.currentScoreComment && (activeStudent.scoreIntials === studentCurrentWeekScore.currentScore)) || 
          ( studentCurrentWeekScore.currentScoreComment && (activeStudent.directGradeEligible === true) ) ?
            studentCurrentWeekScore.currentScoreComment : ''
        }
        isFinalCommentMode={isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ? true : false}
        currentFinalGradeStatus={activeStudent.studentFinalGradeStatus}
        directGradeStudentData={
          (activeStudent.directGradeEligible) ? {
            currentFinalGrade: activeStudent.studentFinalGrade !== undefined ? activeStudent.studentFinalGrade : '',
            newFinalDirectGrade: activeStudent.scoreIntials 
          } : undefined
        }
        cancelFunction={() => setCommentDialogIsOpen(false)}
        submitFunction={(comment: string, finalGradeStatus?: boolean | undefined) =>
          isFinalWeekMode && (viewingWeekType === WeekType.FinalWeek) ? 
            (activeStudent.directGradeEligible ? updateDGStudentsFinalGradeStatus(comment, finalGradeStatus) : updateStudentsFinalGradeStatus(comment, finalGradeStatus)) : 
            updateWeeklyGrowthScore(comment)

        }
      />




    </>
  )
  //#endregion Render:
}

