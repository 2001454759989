/* eslint-disable @typescript-eslint/no-empty-interface */
import React from "react";
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { MdArrowBack } from "react-icons/md"; 
import { FiMail } from "react-icons/fi"; 
import Logo from './layout/Logo';
import { Course } from "../models/Course";
import { TeacherStudent } from "../models/TeacherStudent";
import { Standard } from "../models/Standard";
import { PanelSubHeaderDisplay } from "./layout/PanelTitleBar";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Term } from "../models/Term";
import StandardLineGraph from "./charts/StandardLineGraph";
import { Assignment } from "../models/Assignment";
import { Student } from "../models/Student";
import { convertScoreToWord, convertHomeworkScoreToWord, convertStandardActiveToWord } from '../utils/helpers/stringHelpers';
import { ProficiencyType } from '../models/enums/Standards';
import { getHomeworkMissingStandardsBooleans, assignmentsAreHomework} from '../utils/helpers/generalHelpers';
import AssignmentBar from './charts/AssignmentBar';
import { ScoreWords } from '../models/enums/Standards';
import { IMttInterventions } from "../models/MandatoryTargetedTutoring";
import { getMttAttendanceValue, getMttCommentDate, getMttSessionLabel, getMttSkillsValue } from "../utils/helpers/mttHelpers";
import moment from "moment";
import { defaultDateFormat } from "../common/config";
import WarningIcon from '@mui/icons-material/Warning';

export interface IPrintableCourseReportCardProps {
    allInterventions: Array<IMttInterventions>;
    course: Course;
    isFinal: boolean;
    projectedGrade: string;
    hideProjectedGrade:boolean;
    reportCardKey?: number;
    showWarningIcon?: boolean;
    standards: Array<Standard>;
    student: TeacherStudent | Student;  
    term?: Term;
    togglePrintableCourseReportCard: () => void;
    weeklyGrowth: string;
    
}

export interface IPrintableCourseReportCardState {
}

export class PrintableCourseReportCard extends React.Component<IPrintableCourseReportCardProps, IPrintableCourseReportCardState> {

    alternateRow: React.CSSProperties = {
        backgroundColor: '#f0f2f7',
        marginTop: '0',
    };
    reportCardLabel: React.CSSProperties = {
        fontWeight: 'bold', 
        marginBottom: '.5rem',
    };
    reportCardValue1: React.CSSProperties = {        
        textAlign: 'right',        
        marginBottom: '.5rem',
        textTransform: 'uppercase',
    };
    reportCardValue2: React.CSSProperties = {        
        textAlign: 'right',        
        marginBottom: '.5rem',
    };

    render() {
        const teacherName: string = this.props.course.teacherName !== null ? this.props.course.teacherName : this.props.course.teacherDisplay !== null ? this.props.course.teacherDisplay : '';

        return (            
            <div className={'studentReportCard'} style={{padding: '1rem 0' }} key={this.props.reportCardKey}>               
                {/* Logo & Student Name Header */}
                <Grid container spacing={2} className={'reportCardRow'} style={{marginBottom: '1rem'}} >
                    <Grid item xs={6} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <IconButton 
                            onClick={() => this.props.togglePrintableCourseReportCard()} 
                            className={'back-button'}
                            style={{marginRight: '1rem', backgroundColor: '#ddd', color: '#fff', border: 0 }}
                        >
                            <MdArrowBack size='24' />
                        </IconButton>
                        <Logo textColor={"#275D38"} />
                    </Grid>
                    <Grid item  xs={6}>
                        <h4 style={{float: 'right', marginBottom: 0 }}>{`${this.props.student.lastName}, ${this.props.student.firstName}`}</h4>
                    </Grid>
                </Grid>

                {/* Course Summary Information */}
                <Grid container spacing={2} className={'reportCardRow'} style={{borderTop: 'thick solid #777',marginTop: '0',marginBottom: '16px'}}>
                    <Grid item xs={7} style={{paddingTop: '1rem'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <PanelSubHeaderDisplay 
                                    title={this.props.course.courseName}
                                    icon={
                                        <ContactMailIcon/>
                                    } 
                                    subTitle={teacherName} 
                                    secondaryIcon={<FiMail size='18' />} 
                                    secondarySubTitle={this.props.course.teacherEmail}
                                />
                            </Grid>
                            <Grid item xs={6} style={{paddingRight: '16px'}}>
                                <h6 style={{textAlign: 'right'}}>{this.props.course.periodName}&nbsp;Period</h6>
                                <p style={{textAlign: 'right'}}>{this.props.term ? this.props.term.termName : ''}</p>
                            </Grid> 
                        </Grid>                        
                    </Grid>
                    <Grid item xs={5} style={{paddingTop: '1rem', borderLeft: 'thick solid #ddd'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                            {this.props.hideProjectedGrade == false && ( <p style={this.reportCardLabel}>{this.props.isFinal ? 'Final Grade' : 'Projected Grade'}</p>)}
                                
                            </Grid>
                            <Grid item xs={4}>
                                <p style={this.reportCardValue1}>
                                    {this.props.showWarningIcon && this.props.hideProjectedGrade == false &&(
                                        <WarningIcon sx={{ color: '#e2bf54' }} />
                                    )} &nbsp;
                                    {this.props.hideProjectedGrade == false && this.props.projectedGrade}
                                </p>
                            </Grid>
                        </Grid>

                        {!this.props.isFinal && (
                            <Grid container spacing={2} style={this.alternateRow}>
                                <Grid item xs={8} style={{paddingTop: '5px', paddingBottom: '5px'}}><p style={this.reportCardLabel}>Growth</p></Grid>
                                <Grid item xs={4} style={{paddingTop: '5px', paddingBottom: '5px'}}><p style={this.reportCardValue2}>{this.props.weeklyGrowth}</p></Grid>
                            </Grid>
                        )} 
                        
                    </Grid>
                </Grid>

                {/* Standard Information */}
                {this.props.standards.map((standard, standardIndex) => {
                   
                   const proficiencyArray: Array<number> = [standard.proficiency.exceedsCount, standard.proficiency.meetsCount,standard.proficiency.approachingCount, standard.proficiency.developingCount];

                    const graphLevelsArray: Array<string> = standard.assignments.filter(assignment => {
                        return (assignment.score !== null) && (assignment.standardEventActive === 1);
                    }).sort(function compare(assignmentA:any, assignmentB:any) {
                        const dateA = +new Date(assignmentA['dueDate']);
                        const dateB = +new Date(assignmentB['dueDate']);
                        return dateA - dateB;
                    }).map(item => { 
                        return item.score 
                    });


                    const assignmentsGrouped:Array<any> = standard.assignments.reduce((result:any, currentValue:any) => {
                        (result[currentValue.scoreGroupName] = result[currentValue.scoreGroupName] || []).push(currentValue);
                        return result;
                    }, {});

                    
                    const missingHomeworkBooleans: boolean[] =  getHomeworkMissingStandardsBooleans(standard);

                    return (
                        <Grid container spacing={2} className={'reportCardRow'} style={{border: 'thick solid #ddd', marginBottom: '1rem'}} key={standardIndex}>
                            <Grid item xs={12}>
                                <Grid container spacing={2} style={{borderBottom: 'thick solid #ddd'}}>
                                    <Grid item xs={8} style={{padding: '1rem', borderRight: 'thick solid #ddd'}}>
                                        <h4>{standard.standardName}</h4>
                                    </Grid>
                                    <Grid item xs={4} style={{padding: '1rem'}}>
                                        {/* Only Show following section if Not a Practice/Preparation Standard */}
                                        {!standard.isHomeworkStandard ? (
                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%'}}>
                                                <p style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: '50%', marginBottom: 0 }}>
                                                    <span>E</span>
                                                    <span>M</span>
                                                    <span>A</span>
                                                    <span>D</span>
                                                </p>
                                                <StandardLineGraph 
                                                    proficiencyScore={standard.proficiency.proficiencyScore}
                                                    proficiencyType={ProficiencyType.Word}
                                                    proficiencyArray={proficiencyArray}
                                                    graphLevels={graphLevelsArray}
                                                    viewSkillProficienies={ () => {  }}
                                                    forPrint
                                                />
                                            </div>
                                        ) : (
                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%'}}>
                                                <AssignmentBar assignmentList={missingHomeworkBooleans} />
                                            </div>
                                        )}

                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{marginTop: '0'}}>
                                    <Grid item xs={12} style={{padding: '1rem'}}>
                                        {Object.keys(assignmentsGrouped).map(( target: any, targetIndex, array) => {
                                            const targetAssignments: Array<Assignment> = assignmentsGrouped[target];
                                            const isLast = (targetIndex + 1) === Object.keys(assignmentsGrouped).length;
                                            return (
                                                <div key={targetIndex} style={{padding: '0 15px'}}>
                                                    <Grid container spacing={2} style={{marginTop: '0'}}>
                                                        <Grid item xs={12} style={{paddingLeft: 0, paddingRight: 0}}><h5>{target}</h5></Grid>                                                        
                                                    </Grid>  

                                                    {assignmentsAreHomework(targetAssignments) ? (
                                                        <Grid container spacing={2} style={{borderBottom: 'thin solid #ddd', marginTop: '0'}}>
                                                            <Grid item xs={7} style={{paddingLeft: 0, paddingTop:`5px`, paddingBottom:`5px`}}><h6><strong>Event Name</strong></h6></Grid>
                                                            <Grid item xs={3} style={{paddingTop:`5px`, paddingBottom:`5px`}}><h6><strong>Score</strong></h6></Grid>
                                                            <Grid item xs={2} style={{paddingRight: 0, paddingTop:`5px`, paddingBottom:`5px`}}><h6><strong>Event Date</strong></h6></Grid>
                                                        </Grid>
                                                    ): (
                                                        <Grid container spacing={2} style={{borderBottom: 'thin solid #ddd', marginTop: '0'}}>
                                                            <Grid item xs={5} style={{paddingLeft: 0,paddingTop:`5px`, paddingBottom:`5px`}}><h6><strong>Event Name</strong></h6></Grid>
                                                            <Grid item xs={2} style={{paddingTop:`5px`, paddingBottom:`5px`}}><h6><strong>Score</strong></h6></Grid>
                                                            <Grid item xs={2} style={{paddingTop:`5px`, paddingBottom:`5px`}}><h6><strong>Active</strong></h6></Grid>
                                                            <Grid item xs={3} style={{paddingLeft: 0,paddingTop:`5px`, paddingBottom:`5px`}}><h6><strong>Event Date</strong></h6></Grid>
                                                        </Grid>
                                                    )}
                                                    {targetAssignments.sort(function compare(subItemA:Assignment, subItemB:Assignment) {
                                                        const dateA = +new Date(subItemA.dueDate);
                                                        const dateB = +new Date(subItemB.dueDate);
                                                        return dateA - dateB;
                                                    }).reverse()
                                                    .map(( assignment:Assignment, assignmentIndex:number ) => {
                                                        const isAlternate = assignmentIndex % 2 === 0;
                                                        return (
                                                            <>
                                                                {assignment.isHomework ? (<>                                
                                                                        <Grid container spacing={2} key={assignmentIndex + 'a'} style={isAlternate ? this.alternateRow : {marginTop: '0'}}>
                                                                            <Grid item xs={7} style={{paddingLeft: 0,paddingTop:`5px`, paddingBottom:`5px`}}>{assignment.activityName}</Grid>
                                                                            <Grid item xs={3} style={{paddingTop:`5px`, paddingBottom:`5px`}}>{convertHomeworkScoreToWord(assignment.score)} </Grid>
                                                                            <Grid item xs={2} style={{paddingLeft: 0,paddingTop:`5px`, paddingBottom:`5px`}}>{this.formatDate(assignment.dueDate)}</Grid>
                                                                        </Grid>
                                                                    </>):(<>                                                                     
                                                                        <Grid container spacing={2} key={assignmentIndex + 'a'} style={isAlternate ? this.alternateRow : {marginTop: '0'}}>
                                                                            <Grid item xs={5} style={{paddingLeft: 0,paddingTop:`5px`, paddingBottom:`5px`}}>{assignment.activityName}</Grid>
                                                                            <Grid item xs={2} style={{paddingTop:`5px`, paddingBottom:`5px`}}>
                                                                                {convertScoreToWord(assignment.score)}

                                                                                {(convertScoreToWord(assignment.score) === ScoreWords.Missing && convertStandardActiveToWord(assignment.standardEventActive).toLowerCase() === 'yes') && 
                                                                                    (<WarningIcon sx={{ color: '#e2bf54',marginLeft: '8px' }}/>) 
                                                                                }
                                                                                {(convertScoreToWord(assignment.score) === ScoreWords.Missing && convertStandardActiveToWord(assignment.standardEventActive).toLowerCase() === 'no') &&
                                                                                    (<WarningIcon sx={{ color: '#7b7f8b',marginLeft: '8px', opacity: '.5' }}/>)  
                                                                                }
                                                                            </Grid>
                                                                            <Grid item xs={2} style={{paddingTop:`5px`, paddingBottom:`5px`}}>{convertStandardActiveToWord(assignment.standardEventActive)}</Grid>
                                                                            <Grid item xs={3} style={{paddingRight: 0,paddingTop:`5px`, paddingBottom:`5px`}}>{this.formatDate(assignment.dueDate)}</Grid>
                                                                        </Grid>
                                                                    </>)
                                                                }                                                          
                                                                {((assignment.comments !== null && assignment.comments !== undefined ) && (assignment.comments.length > 0)) &&
                                                                    <Grid container spacing={2} key={assignmentIndex + 'b'} style={isAlternate ? this.alternateRow : {}}>
                                                                        <Grid item xs={12} style={{paddingLeft: '3rem'}}>
                                                                            <p>{assignment.comments}</p>
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                            </>
                                                        );
                                                    })}
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} style={!isLast ? {borderBottom: 'thick solid #ddd', margin:'1rem 0'} : {}} />
                                                    </Grid>
                                                </div>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>                            
                        </Grid>
                    );
                })}

                {/* Intervention History */}
                {this.props.allInterventions.map((intervention, interventionIndex) => {
                    return (
                        <Grid container spacing={2} className={'reportCardRow'} style={{border: 'thick solid #ddd', marginBottom: '1rem'}} key={interventionIndex}>
                            <Grid item xs={12}>
                                <Grid container spacing={2} style={{borderBottom: 'thick solid #ddd'}}>                                    
                                    <Grid item sm={12} style={{padding: '1rem'}}>
                                        <h4>MTT {getMttSessionLabel(intervention)}</h4>
                                    </Grid>
                                </Grid>
                                {/* Intervention Header Information */}
                                <Grid container spacing={2} style={{borderBottom: 'thick solid #ddd'}}>
                                    <Grid item xs={12} style={{padding: '1rem'}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <p><strong>Start Date :</strong></p>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <p>{`${moment(intervention.startDate).format(defaultDateFormat)}`}</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <p><strong>Score :</strong></p>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <p>{intervention.weeklyGrowthScore}</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <p><strong>Skill(s) :</strong></p>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <p>{getMttSkillsValue(intervention)}</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <p><strong>Attendance :</strong></p>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <p>{getMttAttendanceValue(intervention)}</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <p><strong>Enrollment Comment :</strong></p>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <p>{intervention.initialComment}</p>
                                            </Grid>
                                        </Grid>                                        
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={{padding: '1rem'}}>
                                        {/* Intervention Comments */}
                                        {intervention.comments.map((comment, commentIndex) => {
                                            return (
                                                <Grid container spacing={2} key={commentIndex}>
                                                    <Grid item xs={12} style={{padding: '1rem'}}>
                                                        <p>{comment.comments}<br />
                                                            <span style={{ fontSize: '.8rem'}}>{`Attended: ${comment.attendance ? 'Yes' : 'No'}`} | {comment.commentAuthorName} on {getMttCommentDate(comment)}</span>
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}                                        
                                    </Grid>
                                </Grid>                                
                            </Grid>
                        </Grid>
                    );
                })}
            </div>
        );
    }

    private formatDate = (dateString: Date) : string => {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' } as const;
        return new Date(dateString).toLocaleDateString([],options);
    }
}